import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function AjouterCoursUnique(args) {
  const [NomCours, setNomCours] = useState("");
  const [Place, setPlace] = useState("");
  const [Jour, setJour] = useState("");
  const [Heure, setHeure] = useState("");
  const [Code, setCode] = useState("");
  const [Description, setDescription] = useState("");
  const [Type, setType] = useState("Cours");

  const [CSRF] = useState(args.type);

  useEffect(() => {
    console.log(Type);
  }, [Type]);

  const HandleSubmitCours = (e) => {
    e.preventDefault();

    if (
      !NomCours ||
      !Place ||
      !Code ||
      !Jour ||
      !Heure ||
      !Description ||
      !Type
    ) {
      Swal.fire("Une erreur !", "Veuillez remplir tous les champs", "error");
      return;
    }

    if (!/^[0-9]+$/.test(Place)) {
      Swal.fire(
        "Une erreur !",
        "Format de Place incorrect (seulement des chiffres, seulement 2 chiffres)",
        "error",
      );
      return;
    }

    if (Type !== "Cours" && Type !== "Visio") {
      Swal.fire(
        "Une erreur !",
        "Une erreur a été détéctée, veuillez réessayer.",
        "error",
      );
      return;
    }

    if (Description.length > 300) {
      Swal.fire(
        "Une erreur !",
        "La description est trop longue (" +
          Description.length +
          " caractères au lieu de 300 max)",
        "error",
      );
      return;
    }

    if (!(NomCours.length > 0 && NomCours.length <= 40)) {
      Swal.fire(
        "Une erreur !",
        "FRAISS (mais vite fait) ! Le nom du cours est trop long",
        "error",
      );
      return;
    }

    if (Place.length > 2) {
      Swal.fire(
        "Une erreur !",
        "Format de Place incorrect (seulement des chiffres, seulement 2 chiffres)",
        "error",
      );
      return;
    }

    if (!/^[a-zA-z0-9]{1,10}/.test(Code)) {
      Swal.fire("Une erreur !", "Format de code incorrect", "error");
      return;
    }

    if (!/^(([0-9]{4})-([0-9]{2})-([0-9]{2}))+$/.test(Jour)) {
      Swal.fire("Une erreur !", "Format de jour incorrect", "error");
      return;
    }

    if (Date.parse(Jour) / 1000 < Math.floor(Date.now() / 1000)) {
      Swal.fire(
        "Une erreur !",
        "La date ne peut pas être dans le passé !",
        "error",
      );
      return;
    }

    if (Heure === "") {
      Swal.fire("Une erreur !", "Une erreur est survenu...", "error");
      return;
    }

    const params = {
      csrf: CSRF,
      nom: NomCours,
      place: Place,
      code: Code,
      jour: Jour,
      heure: Heure,
      description: Description,
      type: Type,
    };

    axios
      .post("/admin/ajoutCours", params)
      .then(() => {
        Swal.fire("Votre cours a bien été ajouté.");
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        }
      });
  };

  return (
    <form onSubmit={HandleSubmitCours}>
      <div className="form-element unsetWidth">
        <div className="mt80 titlebox9">Ajouter un cours</div>
        <div className="user-box">
          <input
            className="coursFont"
            value={NomCours}
            onChange={(e) => setNomCours(e.target.value)}
            id="nom"
            type="text"
            name="nom"
            required
          />
          <br />
          <label htmlFor="nom">Nom</label>
        </div>
        <div className="user-box">
          <input
            value={Place}
            onChange={(e) => setPlace(e.target.value)}
            id="entreprise"
            type="text"
            name="entreprise"
            required
          />
          <br />
          <label htmlFor="entreprise">Nombre de places</label>
        </div>
        <div className="user-box">
          <label style={{ position: "relative" }} htmlFor="cours">
            Type de cours
          </label>
          <select name="cours" onChange={(e) => setType(e.target.value)}>
            <option value="Cours">Cours</option>
            <option value="Visio">Visio</option>
          </select>
          <br />
        </div>
        <div className="user-box">
          <input
            value={Code}
            onChange={(e) => setCode(e.target.value)}
            id="entreprise"
            type="text"
            name="entreprise"
            required
          />
          <br />
          <label htmlFor="entreprise">Code Entreprise</label>
        </div>
        <div className="user-box">
          <input
            value={Jour}
            onChange={(e) => setJour(e.target.value)}
            type="date"
            id="start"
            name="date"
            required
          ></input>
          <br />
          <input type="hidden" value={CSRF} />
        </div>
        <div className="user-box">
          <input
            value={Heure}
            onChange={(e) => setHeure(e.target.value)}
            id="entreprise"
            type="text"
            name="entreprise"
            required
          />
          <br />
          <label htmlFor="entreprise">Heure (ex: 20h10 - 21h10)</label>
        </div>
        <div className="user-box">
          <p style={{ color: "black" }}>
            Ajouter une description (300 caractères max)
          </p>
          <textarea
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
            rows="15"
            style={{ width: "100%", fontFamily: "sans-serif" }}
          ></textarea>
        </div>

        <button className="gingerlab" type="submit">
          Ajouter
        </button>
      </div>
    </form>
  );
}
