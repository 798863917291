import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

export default function Contact() {
  const reCaptchaRef = useRef();

  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [objet, setObjet] = useState("");
  const [demande, setDemande] = useState("");
  const [telephone, setTelephone] = useState("");
  const [intitulePoste, setIntitulePoste] = useState("");
  const [nombreSalarie, setNombreSalarie] = useState("");
  const [activiteSouhaitee, setActiviteSouhaitee] = useState("");
  const [frequenceCours, setFrequenceCours] = useState("");
  const [captcha, setCaptcha] = useState("");

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      nom === "" ||
      email === "" ||
      objet === "" ||
      demande === "" ||
      telephone === "" ||
      intitulePoste === "" ||
      nombreSalarie === "" ||
      activiteSouhaitee === "" ||
      frequenceCours === ""
    ) {
      Swal.fire("Une erreur !", "Veuillez remplir tous les champs", "error");
      return;
    }

    if (!/^[a-zA-z-\s]+$/.test(nom)) {
      Swal.fire("Une erreur !", "Est-ce bien votre nom ?", "error");
      return;
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      Swal.fire("Une erreur !", "Ce type d'email n'est pas autorisé", "error");
      return;
    }

    const demandeTexte = `
    Numero de téléphone: ${telephone}
    Intitulé du poste: ${intitulePoste}
    Nombre de salarié: ${nombreSalarie}
    Activité souhaitée: ${activiteSouhaitee}
    Frequence: ${frequenceCours}
    Details supplémentaire: ${demande}
    `;

    const params = {
      nom: nom,
      email: email,
      objet: objet,
      demande: demandeTexte,
      captcha: captcha,
    };

    try {
      await axios.post("/sendMessage", params);
      Swal.fire(" Bien joué !", "Votre mail a bien été envoyé", "success");
    } catch (err) {
      if (err.request.status === 429) {
        Swal.fire({
          icon: "error",
          title: "Trop de requêtes, veuillez réessayer plus tard.",
        });
      }
      switch (err.response.data.msg) {
        default:
          break;
        case "error":
          Swal.fire("Une erreur !", "Une erreur est survenue", "error");
          break;
        case "captcha-error":
          Swal.fire("Une erreur !", "Le captcha a expiré", "error");
          break;
      }
    }
  };

  useEffect(() => {
    const loadReCaptchaScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6LeA6NAnAAAAAD2t4RCJHZTaaDVG0SYpgP1ofVo3";
      script.onload = handleReCaptchaLoad;
      document.body.appendChild(script);
    };

    const handleReCaptchaLoad = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LeA6NAnAAAAAD2t4RCJHZTaaDVG0SYpgP1ofVo3")
          .then((token) => {
            setCaptcha(token);
          });
      });
    };

    loadReCaptchaScript();
  }, []);

  return (
    <>
      <main>
        <div
          className="g-recaptcha"
          data-sitekey="6LeOrkMnAAAAABueBdGN0lU86acdem2F3egBK47G"
          data-size="invisible"
          ref={reCaptchaRef}
        ></div>
        <section className="form">
          <h2 className="formp spacebecauseofNumber">
            Vous avez des questions, des commentaires ou des avis à laisser ?
            STUDIO QUATRE est à votre service.
            <p />
            <form onSubmit={handleSubmit}>
              <div className="form-element">
                <div className="user-box">
                  <input
                    id="nom"
                    onChange={handleChange(setNom)}
                    value={nom}
                    type="text"
                    name="nom"
                    required
                  />
                  <label htmlFor="nom">Nom et prénom</label>
                </div>
                <div className="user-box">
                  <input
                    id="email"
                    onChange={handleChange(setEmail)}
                    value={email}
                    type="email"
                    name="email"
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="user-box">
                  <input
                    id="objet"
                    onChange={handleChange(setObjet)}
                    value={objet}
                    type="text"
                    name="objet"
                    required
                  />
                  <label htmlFor="objet">Objet de la demande</label>
                </div>
                <div className="user-box">
                  <input
                    id="telephone"
                    onChange={handleChange(setTelephone)}
                    value={telephone}
                    type="text"
                    name="telephone"
                    required
                  />
                  <label htmlFor="telephone">Téléphone</label>
                </div>
                <div className="user-box">
                  <input
                    id="intitulePoste"
                    onChange={handleChange(setIntitulePoste)}
                    value={intitulePoste}
                    type="text"
                    name="intitulePoste"
                    required
                  />
                  <label htmlFor="intitulePoste">Intitulé du poste</label>
                </div>
                <div className="user-box">
                  <input
                    id="nombreSalarie"
                    onChange={handleChange(setNombreSalarie)}
                    value={nombreSalarie}
                    type="text"
                    name="nombreSalarie"
                    required
                  />
                  <label htmlFor="nombreSalarie">Nombre de salarié</label>
                </div>
                <div className="user-box">
                  <input
                    id="activiteSouhaitee"
                    onChange={handleChange(setActiviteSouhaitee)}
                    value={activiteSouhaitee}
                    type="text"
                    name="activiteSouhaitee"
                    required
                  />
                  <label htmlFor="activiteSouhaitee">Activité souhaitée</label>
                </div>
                <div className="user-box">
                  <input
                    id="frequenceCours"
                    onChange={handleChange(setFrequenceCours)}
                    value={frequenceCours}
                    type="text"
                    name="frequenceCours"
                    required
                  />
                  <label htmlFor="frequenceCours">Fréquence des cours</label>
                </div>
                <div className="user-box">
                  <label className="demandelabel" htmlFor="demande">
                    Détails supplémentaire
                  </label>
                  <textarea
                    onChange={handleChange(setDemande)}
                    value={demande}
                    id="demande"
                    className="demande"
                    name="demande"
                  />
                </div>
                <button className="gingerlab" type="submit">
                  Envoyer
                </button>
              </div>
            </form>
          </h2>
        </section>
      </main>
    </>
  );
}
