import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../img/logo.webp";
import Line2 from "../img/line2.png";
import { decode } from "html-entities";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
var validator = require("email-validator");

export default function Inscription() {
  const { id } = useParams();
  const [captcha, setCaptcha] = useState("");
  const [Data, setData] = useState();
  const [Nom, setNom] = useState("");

  useEffect(() => {
    axios
      .post("/getcsrf")
      .then((dataCSRF) => {
        return axios.post("/booking/load", { code: id });
      })
      .then((data) => {
        setNom(data.data[1]);
        setData(data.data[0].sort((a, b) => a.timestamp - b.timestamp));
      })
      .catch((err) => {
        setData(err.response.data.msg);
        if (err.request.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        }
      })
      .finally(() => {
        var loader = document.getElementById("loader");
        loader.classList.add("nodisplay");
      });
  }, [id]);

  useEffect(() => {
    const loadReCaptchaScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6LeA6NAnAAAAAD2t4RCJHZTaaDVG0SYpgP1ofVo3";
      script.onload = handleReCaptchaLoad;
      document.body.appendChild(script);
    };

    const handleReCaptchaLoad = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LeA6NAnAAAAAD2t4RCJHZTaaDVG0SYpgP1ofVo3")
          .then((token) => {
            setCaptcha(token);
          });
      });
    };

    loadReCaptchaScript();
  }, []);

  const dateToReadable = (timestamp) => {
    const date = new Date(timestamp * 1000);

    // Tableaux pour les noms des jours et des mois
    const jours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const mois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    // Obtenir le nom du jour, le jour du mois et le mois
    const nomJour = jours[date.getDay()];
    const jourDuMois = date.getDate();
    const nomMois = mois[date.getMonth()];
    const annee = date.getFullYear();

    // Créer la chaîne de date formatée
    return `${nomJour} ${jourDuMois} ${nomMois} ${annee}`;
  };

  const book = (data) => {
    if (data.type === "Visio") {
      return (window.location.href = "https://meet.jit.si/" + data.token);
    }
    Swal.fire({
      title: "Inscription",
      html: `<input style="font-family: sans-serif;" type="text" id="name" class="swal2-input" autocomplete="off" placeholder="Nom/prénom">
            <input style="font-family: sans-serif;" type="email" id="email" class="swal2-input" autocomplete="off" placeholder="Adresse email">`,
      confirmButtonText: "Réserver",
      focusConfirm: false,
      preConfirm: () => {
        const name = Swal.getPopup().querySelector("#name").value;
        const email = Swal.getPopup().querySelector("#email").value;
        if (!name || !email) {
          Swal.showValidationMessage(`Veuillez remplir tous les champs`);
        }
        if (!validator.validate(email)) {
          Swal.showValidationMessage(`Cet email n'est pas valide`);
        }

        axios
          .post("/booking/booking", { captcha, name, email, token: data.token })
          .then((response) => {
            if (response.data.msg === "done") {
              return Swal.fire({
                icon: "success",
                text: "Vous vous êtes pré-inscrit avec succès, il faut attendre la confirmation d'un modérateur afin de pouvoir acceder à cette séance.",
              });
            }
          })
          .catch((err) => {
            if (err?.request?.status === 429) {
              Swal.fire({
                icon: "error",
                title: "Trop de requêtes, veuillez réessayer plus tard.",
              });
            } else if (err?.response?.data?.msg) {
              Swal.fire({
                icon: "error",
                title: err.response.data.msg,
              });
            } else {
              Swal.fire(
                "Une erreur a été détécté, merci de réesayer plus tard.",
              );
            }
          });
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Inscription - STUDIO QUATRE</title>
        <meta
          name="description"
          content="Retrouvez ici nos programmes sportifs PDF, avec options de suivi si souhaité"
        />
        <link rel="canonical" href="https://lestudioquatre.com/boutique" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div id="loader" className="hiuxxw loaderPay">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 style={{ color: "white" }}>Veuillez patienter</h2>
      </div>

      <div className="container-inscription">
        {Array.isArray(Data) ? (
          <h1 className="h1-inscription">
            Prochains cours à venir pour l'entreprise {decode(Nom)}
          </h1>
        ) : Data === "empty" ? (
          <h1 className="h1-inscription error-h1">
            Cette entreprise n'a pas été trouvé
          </h1>
        ) : Data === "error" ? (
          <h1 className="h1-inscription error-h1">Une erreur a été détécté</h1>
        ) : (
          <h1 className="h1-inscription error-h1">Une erreur a été détécté</h1>
        )}
      </div>

      {Array.isArray(Data) && Data.length > 0 ? (
        Data.map(
          (
            seance,
            index, // Changez "data" en "seance" ici pour éviter la confusion
          ) => (
            <section key={index} className="inscription presentation-coach s85">
              <div className="wrapper-coach">
                <div className="presentation-coach-img">
                  <img alt="coach jemsky" src={Logo} className="logobook" />
                </div>
                <div className="presentation-coach-txt">
                  <div className="qualities">
                    <article className="inscription-seance">
                      <h3>{decode(seance.nom)}</h3>
                    </article>
                    <article className="inscription-seance">
                      <h3>{dateToReadable(seance.timestamp)}</h3>
                    </article>
                    <article className="inscription-seance">
                      <h3>{seance.timeslot}</h3>
                    </article>
                    <article className="inscription-seance">
                      <h3>{seance.type}</h3>
                    </article>
                    <article className="inscription-seance">
                      {seance.place < 5 ? (
                        <h3 style={{ color: "red" }}>
                          {seance.place} places restantes
                        </h3>
                      ) : (
                        <h3>{seance.place} places restantes</h3>
                      )}
                    </article>
                  </div>
                  <button
                    onClick={() => book(seance)}
                    className="button-inscription"
                  >
                    {seance.type === "Visio"
                      ? "Acceder à la visioconférence"
                      : "Réserver cette séance"}
                  </button>
                </div>
              </div>
              <img src={Line2} alt="triple line ui/ux" className="img-line" />
            </section>
          ),
        )
      ) : Array.isArray(Data) && Data.length === 0 ? (
        <section className="inscription presentation-coach s85">
          <div className="wrapper-coach">
            <div className="presentation-coach-img">
              <img
                style={{ filter: "blur(30px)" }}
                alt="coach jemsky"
                src={Logo}
                className="logobook"
              />
            </div>
            <div className="presentation-coach-txt">
              <h2>Aucun cours n'est disponible pour le moment</h2>
              <div className="qualities">
                <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
              </div>
            </div>
          </div>
          <img src={Line2} alt="triple line ui/ux" className="img-line" />
        </section>
      ) : (
        <>
          <section className="inscription presentation-coach s85">
            <div className="wrapper-coach">
              <div className="presentation-coach-img">
                <img
                  style={{ filter: "blur(30px)" }}
                  alt="coach jemsky"
                  src={Logo}
                  className="logobook"
                />
              </div>
              <div className="presentation-coach-txt">
                <div className="qualities">
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                </div>
              </div>
            </div>
            <img src={Line2} alt="triple line ui/ux" className="img-line" />
          </section>
          <section className="inscription presentation-coach s85">
            <div className="wrapper-coach">
              <div className="presentation-coach-img">
                <img
                  style={{ filter: "blur(30px)" }}
                  alt="coach jemsky"
                  src={Logo}
                  className="logobook"
                />
              </div>
              <div className="presentation-coach-txt">
                <div className="qualities">
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                  <article className="skeleton-loading skeleton-bloc inscription-seance"></article>
                </div>
              </div>
            </div>
            <img src={Line2} alt="triple line ui/ux" className="img-line" />
          </section>
        </>
      )}
    </>
  );
}
