import { NavLink } from "react-router-dom"
import { Icon } from '@iconify/react';
import { useEffect, useState } from "react";
import { decode } from "html-entities";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

export default function StatistiquesAdmin() {

    const [achatL, setAchatL] = useState("Nan")
    const [adherentsL, setadherentsL] = useState("Nan")
    const [CoursL, setCoursL] = useState("Nan")
    const [CoursListingL, setCoursListingL] = useState("Nan")

    const [Achats, setAchats] = useState([])
    const [Cours, setCours] = useState([])
    const [CoursListing, setCoursListing] = useState([])

    useEffect(() => {
        document.body.classList.add('RemoveXScroll')
    }, [])

    useEffect(() => {
        axios.post("/admin/getcsrf")
            .then((data) => {
                if (!data.data.login) {
                    window.location.href = "/wsq700/"
                }

                const csrf = data.data.csrf
                var loader = document.getElementById("loader");
                loader.classList.add('nodisplay')

                axios.post('/admin/stats', { csrf })
                    .then((data) => {
                        data = data.data.msg

                        setAchatL(data[0].length);
                        setAchats(data[0])
                        // 1 : adhérents

                        setadherentsL(data[1][0].adherents);

                        // 3 : cours adherents
                        setCoursL(data[2].length);
                        setCours(data[2]);

                        // 4 cours
                        setCoursListingL(data[3].length);
                        setCoursListing(data[3]);

                        var loader = document.getElementById("loader");
                        loader.classList.add('nodisplay')
                    })
                    .catch((err) => {
                        if (err?.request?.status === 429) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Trop de requêtes, veuillez réessayer plus tard.'
                            })
                        } else if (err?.response?.data?.msg) {
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.msg
                            })
                        } else {
                            Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                        }
                    })
            })
            .catch((err) => {
                if (err?.request?.status === 429) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Trop de requêtes, veuillez réessayer plus tard.'
                    })
                } else if (err?.response?.data?.msg) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.msg
                    })
                } else {
                    Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                }
            })
    }, []);


    useEffect(() => {
        let btn = document.querySelector("#btn");
        let sidebar = document.querySelector(".sidebar");

        btn.onclick = function () {
            sidebar.classList.toggle("active");
        }
    })

    const Deconnexion = () => {
        axios.post("/logout")
            .then(() => {
                window.location.href = "/"
            })

    }

    const scrollDiv = (which) => {
        const red = document.querySelectorAll(".faq-question");
        const arrow = document.querySelectorAll("#arrow");
        const text = document.querySelectorAll(".displayNull");

        text[which].classList.toggle("faq-test")
        red[which].classList.toggle("effect-slidebox")
        arrow[which].classList.toggle("arrow-clicked")

    };





    return (
        <>
            <Helmet>
                <title>Admin Side - STUDIO QUATRE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div id="loader" className="hiuxxw loaderPay">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                <h2 style={{ color: "white" }}>Veuillez patienter</h2>
            </div>
            <div className="sidebar">
                <div className="logo_content">
                    <div className="logo">
                        <i className="bx bxl-c-plus-plus" style={{ fontSize: '30px' }} />
                        <div className="logoname" style={{ marginLeft: '5px' }}>Le Studio Quatre</div>
                    </div>
                    <i className="bx bx-menu-alt-right" id="btn" style={{ fontSize: '25px' }}>
                        <Icon icon="bx:menu-alt-right" />
                    </i>
                </div>
                <ul className="nav_list">
                    <li>
                        <NavLink to="/wsq700/dashboard">
                            <i className="bx bx-user">
                                <Icon icon="material-symbols:add" />
                            </i>
                            <span className="link_names">Dashboard</span>
                        </NavLink>
                        <span className="tooltip">Dashboard</span>
                    </li>
                    <li>
                        <NavLink to="/wsq700/validation">
                            <i className="bx bx-user">
                                <Icon icon="grommet-icons:validate" />
                            </i>
                            <span className="link_names">Validation</span>
                        </NavLink>
                        <span className="tooltip">Validation</span>
                    </li>
                    <li>
                        <NavLink to="/wsq700/privatelinks">
                            <i className="bx bx-user">
                                <Icon icon="material-symbols:private-connectivity" />
                            </i>
                            <span className="link_names">Entreprise</span>
                        </NavLink>
                        <span className="tooltip">Entreprise</span>
                    </li>
                    <li>
                        <NavLink to="/wsq700/chat">
                            <i className="bx bx-user">
                                <Icon icon="material-symbols:chat" />
                            </i>
                            <span className="link_names">Chat</span>
                        </NavLink>
                        <span className="tooltip">Chat</span>
                    </li>
                    <li onClick={Deconnexion}>
                        <a href="#logout">
                            <i className="bx bx-chat">
                                <Icon icon="material-symbols:logout-rounded" />
                            </i>
                            <span className="link_names">Déconnexion</span>
                        </a>
                        <span className="tooltip">Déconnexion</span>
                    </li>
                </ul>
            </div>
            <div className="home_content">
                <article className="stats__">
                    <h2>Statistiques :</h2>
                    <p>Achats : {achatL} </p>
                    <p>Adhérents (mobile) : {adherentsL}</p>
                    <p>Résérvation Cours : {CoursL}</p>
                    <p>Cours : {CoursListingL}</p>
                </article>
                <article className="faq-question">
                    <div onClick={() => scrollDiv(0)} className='divDisplay'>
                        <div className="border-arrow">
                            <div id="arrow" className="arrow right"></div>
                        </div>
                        <h3>Achats</h3>
                    </div>
                    <div className='displayNull'>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Option</th>
                                    <th>Prix</th>
                                    <th>Statut</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    achatL !== 0
                                        ?
                                        Achats.map((Res, index) =>

                                            <>
                                                <tr key={index}>
                                                    <td>{Achats[index].nom} </td>
                                                    <td>{Achats[index].options} </td>
                                                    <td>{Achats[index].prix} € </td>
                                                    <td>{Achats[index].statut}</td>
                                                    <td>{Achats[index].email}</td>
                                                </tr>
                                            </>
                                        )
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                </article>
                <article className="faq-question">
                    <div onClick={() => scrollDiv(1)} className='divDisplay'>
                        <div className="border-arrow">
                            <div id="arrow" className="arrow right"></div>
                        </div>
                        <h3>Réservation Cours</h3>
                    </div>
                    <div className='displayNull'>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Email</th>
                                    <th>Programme</th>
                                    <th>Jour</th>
                                    <th>Heure</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Cours.map((Res, index) =>

                                        <>
                                            <tr key={index}>
                                                <td>{Cours[index].nom} </td>
                                                <td>{Cours[index].email} </td>
                                                <td>{Cours[index].programme} </td>
                                                <td>{Cours[index].jour} </td>
                                                <td>{Cours[index].timeslot} </td>
                                            </tr>
                                        </>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </article>
                <article className="faq-question">
                    <div onClick={() => scrollDiv(2)} className='divDisplay'>
                        <div className="border-arrow">
                            <div id="arrow" className="arrow right"></div>
                        </div>
                        <h3>Cours</h3>
                    </div>
                    <div className='displayNull'>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Places restante</th>
                                    <th>Entreprise</th>
                                    <th>Jour</th>
                                    <th>Heure</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    CoursListing.map((Res, index) =>

                                        <>
                                            <tr key={index}>
                                                <td>{decode(CoursListing[index].nom)} </td>
                                                <td>{(CoursListing[index].place)}</td>
                                                <td>{CoursListing[index].entreprise} </td>
                                                <td>{CoursListing[index].jour} </td>
                                                <td>{CoursListing[index].timeslot} </td>
                                            </tr>
                                        </>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </article>
            </div>
        </>
    );
}