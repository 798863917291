import Menu from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function Meditrain() {
  return (
    <>
      <Helmet>
        <title>MediTrain - STUDIO QUATRE</title>
        <meta
          name="description"
          content="Besoin de faire redescendre la pression, MediTrain est là pour vous, détente assuré !"
        />
        <meta property="og:site_name" content="STUDIO QUATRE" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://lestudioquatre.com/meditrain"
        />
        <meta property="og:title" content="STUDIO QUATRE - MediTrain" />
        <meta
          property="og:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          property="og:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@studioquatre" />
        <meta name="twitter:title" content="STUDIO QUATRE - MediTrain" />
        <meta
          name="twitter:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          name="twitter:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta
          name="twitter:url"
          content="https://lestudioquatre.com/meditrain"
        />
        <link rel="canonical" href="https://lestudioquatre.com/meditrain" />
      </Helmet>
      <Menu />
      <main className="meditrain-bg">
        <section className="afheader">
          <article className="inafheader">
            <div className="inafheadertxt">
              <h1>MediTrain</h1>
              <p>
                Besoin de faire redescendre la pression, vous relaxer ou encore
                lâcher prise ? Ne cherchez plus !
              </p>
            </div>
            <img
              src="img/meditrain.svg"
              className="meditation"
              alt="méditation animé"
            />
          </article>
        </section>
        <div className="bluwa">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="meditrain-bg"
          >
            <title>wavemedi</title>
            <path
              fill="bisque"
              fill-opacity="1"
              d="M0,96L48,90.7C96,85,192,75,288,106.7C384,139,480,213,576,208C672,203,768,117,864,90.7C960,64,1056,96,1152,122.7C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
          <section className="blocmediz">
            <div>
              <h2>
                "Exclusivement proposé par Studio Quatre, les entrainements
                MediTrain ont été développé dans le souci d'accompagner votre
                bien-être."
              </h2>
            </div>
          </section>
          <section className="bloctree">
            <article>
              <h2>
                Parce que la société dans laquelle nous vivons est en mouvement
                perpétuel avec des rythmes de travail et de vie très intenses.
              </h2>
            </article>
            <article>
              <h2>
                Parce que nos quotidiens sont de plus en plus agités et envahis
                par le stress, la fatigue et les charges mentales diverses.
              </h2>
            </article>
            <article>
              <h2>
                Parce que nos corps et nos esprits sont sollicités en permanente
                et ne trouvent plus le temps de récupérer et de se mettre au
                repos.
              </h2>
            </article>
          </section>
          <section className="bloctree blocText justify-text">
            <article>
              <h2>
                Bien que le sport ou l’activité physique en général soient
                importants pour notre santé corporelle, ces activités ne
                suffisent pas en elles-mêmes pour permettre un vrai apaisement
                de l’esprit. D’autres outils et méthodes, plus adaptés, plus
                efficaces et surtout plus personnalisables existent : la
                méditation, la mindfulness (pleine conscience) ou encore les
                exercices de respiration et de concentration. Car le corps, il
                est tout aussi important d’entrainer l’esprit !
              </h2>
            </article>
          </section>

          <section className="bloctree blocStep">
            <h2>Séance MediTrain type</h2>
            <div>
              <div class="journey">
                <div class="point-container">
                  <div class="point"></div>
                  <div class="line"></div>
                </div>
                <p class="step">Échauffement</p>
              </div>
              <div class="journey">
                <div class="point-container">
                  <div class="point"></div>
                  <div class="line"></div>
                </div>
                <p class="step">Entraînement physique en pleine conscience</p>
              </div>
              <div class="journey">
                <div class="point-container">
                  <div class="point"></div>
                  <div class="line"></div>
                </div>
                <p class="step">Étirements</p>
              </div>
              <div class="journey">
                <div class="point-container">
                  <div class="point"></div>
                </div>
                <p class="step">Méditation guidée et respiration</p>
              </div>
            </div>
          </section>

          <section className="bloctree blocText">
            <article>
              <h2>
                MediTrain est là dans cet objectif.
                <br />
                C’est en associant entrainement physique et méditation que
                MediTrain vous accompagne sur le chemin de la relaxation et de
                l’apaisement du mental.
                <br />
                Personnalisé à vos besoins, MediTrain réuni pour vous les
                meilleurs techniques et outils afin de concevoir un parcours et
                des séances MediTrain adaptés à vos envies, vos exigences, votre
                personnalité et vos contraintes.
              </h2>
            </article>
          </section>

          <section className="blocmediz endmeditrain">
            <div>
              <h2>
                On vous attend pour la prochaine phase de votre développement
                personnel !
                <br />A tout moment MediTrain est là pour vous!
              </h2>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
