import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import AjouterEntreprise from "../../components/AjouterEntreprise";
import AjouterCoursUnique from "../../components/AjouterCoursUnique";
import axios from "axios";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import AjouterNewsletter from "../../components/AjouterNewsletter";
import { QrCodeScan } from "../../components/QrCodeScan";
import { Scanner } from "@yudiel/react-qr-scanner";

export default function DashboardAdmin() {
  const [CSRF, setCSRF] = useState("");
  const [appel, setAppel] = useState(false);
  const lastCallTime = useRef(Date.now());

  const showPopup = () => {
    Swal.fire({
      title: 'Dashboard Admin',
      width: '90%',
      padding: '3em',
      background: '#fff',
      backdrop: `
        rgba(0,0,123,0.4)
        url("https://sweetalert2.github.io/images/nyan-cat.gif")
        left top
        no-repeat
      `,
      html: `
        <div id="popup-content">
          <!-- HTML et composants principaux pour la pop-up -->
          ${document.querySelector('.home_content').innerHTML}
        </div>
      `,
      showCloseButton: true,
      focusConfirm: false,
    });
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (!token) {
      return;
    }
    axios
      .post("/admin/cours/nextcours", { token })
      .then((data) => {
        data = data.data;
        if (!data[0]?.nom) {
          return Swal.fire({
            icon: "error",
            title: "Personne n'est inscrit à ce cours",
          });
        }

        Swal.fire({
          title: `${data[0]?.programme}`,
          html: `
          <table class="nextcours">
            <thead>
              <tr>
                <th>N°</th>
                <th>Nom</th>
              </tr>
            </thead>
            <tbody>
              ${data
                .map(
                  (item, index) => `
                <tr>
                  <td>${index + 1}</td>
                  <td style='color: white; background-color: ${
                    item.appel ? "green" : "red"
                  };'>${item.nom}</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
            <button id="faireAppel">Faire l'appel</button>
          </table>
        `,
          focusConfirm: false,
          didOpen: () => {
            document
              .getElementById("faireAppel")
              .addEventListener("click", () => {
                setAppel(data[0].jour);
                Swal.close();
              });
          },
        });
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        } else {
          Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.");
        }
        window.location.href =
          window.location.origin + window.location.pathname;
      });
  }, []);

  const loadCoursToday = () => {
    axios
      .post("/admin/cours/nextcours")
      .then((data) => {
        data = data.data;
        Swal.fire({
          title: `Cours d'aujourd'hui`,
          html: `
          <table class="nextcours">
            <thead>
              <tr>
                <th>Cours</th>
                <th>Entreprise</th>
              </tr>
            </thead>
            <tbody>
              ${data
                .map(
                  (item) => `
                <tr>
                  <td>${item.nom}</td>
                  <td>${item.entreprise}</td>
                  ${
                    item.type === "Visio"
                      ? `<td
                        onclick="window.location.href = 'https://meet.jit.si/${item.token}' "
                        class="voircours"
                      >
                        Visioconférence
                      </td>`
                      : `<td onclick="window.location.href = window.location.origin + window.location.pathname + '?token=${item.token}' " class="voircours">Voir</td>`
                  } </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        `,
          focusConfirm: false,
        });
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        } else {
          Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.");
        }
      });
  };

  useEffect(() => {
    axios
      .post("/admin/getcsrf")
      .then((data) => {
        if (!data.data.login) {
          window.location.href = "/wsq700/";
        }

        setCSRF(data.data.csrf);
        var loader = document.getElementById("loader");
        loader.classList.add("nodisplay");
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        } else {
          Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.");
        }
      });
  }, []);

  useEffect(() => {
    let btn = document.querySelector("#btn");
    let sidebar = document.querySelector(".sidebar");

    btn.onclick = function () {
      sidebar.classList.toggle("active");
    };
  });

  const Deconnexion = () => {
    axios.post("/logout").then(() => {
      window.location.href = "/";
    });
  };

  const handleQrCode = async (code) => {
    const currentTime = Date.now();
    if (currentTime - lastCallTime.current < 3000) {
      console.log("Appel trop fréquent, ignoré");
      return;
    }

    lastCallTime.current = currentTime;

    Swal.fire({
      title: "Traitement en cours...",
      text: "Veuillez patienter",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await axios.post("/admin/cours/appel", {
        token: code,
        day: appel,
      });
      if (response.data) {
        await Swal.fire({
          title: "Succès",
          text: "Le QR Code a été traité avec succès !",
          icon: "success",
        });
      } else {
        await Swal.fire({
          title: "Erreur",
          text: "Ce QR code n'existe pas ou n'est pas inscrit sur ce cours",
          icon: "error",
        });
      }
    } catch (err) {
      await Swal.fire({
        title: "Erreur",
        text:
          err.response?.data?.message ||
          "Le traitement du QR est impossible, erreur inconnue.",
        icon: "error",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Admin Side - STUDIO QUATRE</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div id="loader" className="hiuxxw loaderPay">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 style={{ color: "white" }}>Veuillez patienter</h2>
      </div>
      <div className="sidebar">
        <div className="logo_content">
          <div className="logo">
            <i className="bx bxl-c-plus-plus" style={{ fontSize: "30px" }} />
            <div className="logoname" style={{ marginLeft: "5px" }}>
              Le Studio Quatre
            </div>
          </div>
          <i
            className="bx bx-menu-alt-right"
            id="btn"
            style={{ fontSize: "25px" }}
          >
            <Icon icon="bx:menu-alt-right" />
          </i>
        </div>
        <ul className="nav_list">
          <li>
            <NavLink to="/wsq700/dashboard">
              <i className="bx bx-user">
                <Icon icon="material-symbols:add" />
              </i>
              <span className="link_names">Dashboard</span>
            </NavLink>
            <span className="tooltip">Dashboard</span>
          </li>
          <li>
            <NavLink to="/wsq700/stats">
              <i className="bx bx-user">
                <Icon icon="material-symbols:query-stats" />
              </i>
              <span className="link_names">Statistiques</span>
            </NavLink>
            <span className="tooltip">Statistiques</span>
          </li>
          <li>
            <NavLink to="/wsq700/validation">
              <i className="bx bx-user">
                <Icon icon="grommet-icons:validate" />
              </i>
              <span className="link_names">Validation</span>
            </NavLink>
            <span className="tooltip">Validation</span>
          </li>
          <li>
            <NavLink to="/wsq700/privatelinks">
              <i className="bx bx-user">
                <Icon icon="material-symbols:private-connectivity" />
              </i>
              <span className="link_names">Entreprise</span>
            </NavLink>
            <span className="tooltip">Entreprise</span>
          </li>
          <li>
            <NavLink to="/wsq700/chat">
              <i className="bx bx-user">
                <Icon icon="material-symbols:chat" />
              </i>
              <span className="link_names">Chat</span>
            </NavLink>
            <span className="tooltip">Chat</span>
          </li>
          <li onClick={Deconnexion}>
            <a href="#logout">
              <i className="bx bx-chat">
                <Icon icon="material-symbols:logout-rounded" />
              </i>
              <span className="link_names">Déconnexion</span>
            </a>
            <span className="tooltip">Déconnexion</span>
          </li>
        </ul>
      </div>
      <div className="home_content">
        {appel && (
          <Scanner onScan={(result) => handleQrCode(result[0].rawValue)} />
        )}
        <div onClick={loadCoursToday} className="liste">
          <p>Acceder à la liste des inscrits pour les cours d'aujourd'hui</p>
        </div>
        {CSRF ? (
          <>
            <AjouterEntreprise type={CSRF} />
            <AjouterCoursUnique type={CSRF} />
            <AjouterNewsletter type={CSRF} />
          </>
        ) : null}
      </div>
    </>
  );
}
