import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Menu(prop) {
  useEffect(() => {
    if (prop.prop === "-50") {
      document.getElementById("top-nav").classList.add("recentralize");
    }

    if (prop.home) {
      document.getElementById("header").classList.add("home-gradient");
    }
  }, [prop]);

  return (
    <header id="header">
      <nav>
        <input type="checkbox" id="togglenav" name="toggle-nav" />
        <label htmlFor="toggle-nav">
          <span style={{ color: "#1e1e1e" }} className="menu-icon" />
        </label>
        <section id="side-nav-panel" className="side-nav-panel">
          <ul id="top-nav" className="top-nav">
            <li>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/meditrain">Meditrain</NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
          </ul>
        </section>
      </nav>
      <a href="/" className="header-logo">
        <img src="/img/logo.webp" width={100} height={96} alt="Logo" />
      </a>
    </header>
  );
}
