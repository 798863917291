import Menu from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";

import Athlete from "../img/athlet.png";
import Line2 from "../img/line2.png";
import Particularite from "../img/treadmill.png";
import Diploma from "../img/diploma.png";
import Phone from "../img/phone.png";
import Mockup from "../img/mockup.png";
import QRcode from "../img/qrapp.png";
import Contact from "./Contact";
import { useState } from "react";

export default function Home() {
  const [selected, setSelected] = useState(0);

  const text = [
    {
      title: "Les particularités du Studio Quatre en bref",
      text: "Le Studio Quatre, c'est une équipe de coachs sportifs qualifiés et formés pour entraîner divers groupes peut importe leur forme physique.",
    },
    {
      title: "Un coach sportif qualifié et diplomé",
      text: "Doté d'un BPJEPS AF, Le Studio Quatre vous accompagnera à atteindre vos objectifs, de perte de poids, prise de masse ou encore vous accompagner dans le cadre d'une rééducation/réhabilitation.",
    },
    {
      title: "Une application mobile optimale",
      text: "STUDIO QUATRE offre une application mobile entièrement conçue par ses soins, vous permettant de réserver vos séances facilement.",
    },
  ];

  const Descriptif = (which) => {
    Swal.fire({
      title: text[which].title,
      text: text[which].text,
    });
  };

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  const downloadApp = () => {
    const mobile = getMobileOS();

    switch (mobile) {
      default:
        break;
      case "Android":
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.st4";
        break;
      case "iOS":
        window.location.href =
          "https://apps.apple.com/us/app/studio-quatre/id6466744865";
        break;
      case "Other":
        Swal.fire({
          title: "Téléchargez l'application dès maintenant sur Ios et Android",
          imageUrl: QRcode,
        });
    }
  };

  const presentation = [
    {
      image: "/img/machines.webp",
      text: "Nos offres sportives adaptées assurent le bien-être physique et mental de vos employés en entreprise. En proposant des activités variées comme le yoga, le fitness, et des sports collectifs, nous aidons à réduire le stress, à améliorer la concentration et à renforcer la cohésion d'équipe.",
    },
    {
      image: "/img/bienetre.webp",
      text: "Investir dans le bien-être de vos employés améliore leur épanouissement, créativité et productivité. En soutenant une culture de bien-être, vous encouragez des employés plus heureux et plus engagés, ce qui se traduit par une augmentation de la créativité et de l'innovation au sein de l'entreprise.",
    },
    {
      image: "/img/cohesion.webp",
      text: "L'objectif est d'améliorer la cohésion d'équipe et de créer des liens entre employés de différents métiers. En favorisant des interactions au-delà des rôles professionnels habituels, nos programmes sportifs permettent de construire une meilleure compréhension et une solidarité entre les départements.",
    },
    {
      image: "/img/partnership.webp",
      text: "Nous définissons ensemble une offre qui correspond à vos besoins et à votre budget. En collaborant étroitement avec vous, nous nous assurons que les solutions proposées sont parfaitement alignées avec les attentes de votre entreprise et les ressources disponibles.",
    },
    {
      image: "/img/communication.webp",
      text: "Nous créons une page dédiée pour l'inscription de vos équipes ainsi que du matériel de communication prêt à l'emploi. Cette plateforme centralisée facilite le processus d'inscription et permet à vos employés de s'inscrire rapidement et facilement aux activités sportives proposées.",
    },
    {
      image: "/img/workout.webp",
      text: "Vos employés s'inscrivent et peuvent participer aux cours grâce à un système d'inscription convivial et intuitif. Chaque participant dispose d'un espace dédié où il peut consulter les horaires des cours, réserver ses activités préférées. STUDIO QUATRE garantit une expérience utilisateur fluide et personnalisée, renforçant ainsi l'engagement et la motivation des participants.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          STUDIO QUATRE - Coach Sportif à Asnières sur Seine avec salle de sport
          privé
        </title>
        <meta
          name="description"
          content="STUDIO QUATRE met à disposition des coachs sportifs pour des cours en entreprise"
        />
        <meta property="og:site_name" content="STUDIO QUATRE" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lestudioquatre.com/" />
        <meta property="og:title" content="STUDIO QUATRE - Accueil" />
        <meta
          property="og:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          property="og:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@studioquatre" />
        <meta name="twitter:title" content="STUDIO QUATRE - Accueil" />
        <meta
          name="twitter:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          name="twitter:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:url" content="https://lestudioquatre.com/" />
        <link rel="canonical" href="https://lestudioquatre.com"></link>
      </Helmet>

      <Menu home={true} />
      <div className="topgradient"></div>
      <section className="gradient-section">
        <div className="gradient-line">
          <h1 className="sq4-title">STUDIO QUATRE</h1>
          <p className="sq4-p">LE SPORT C'EST L'ADAPTATION AVANT TOUT</p>
        </div>
        <div className="neon-container">
          <div className="neon-bg" />
          <img className="neon-image" src={Athlete} alt="Athlete" />
        </div>
      </section>

      <main>
        <section className="s85">
          <h2 className="st4-present">LE STUDIO QUATRE C'EST :</h2>
          <div className="undefined-section">
            <article>
              <div
                style={{ backgroundColor: "#908D95" }}
                className="img-container"
              >
                <img
                  alt="treadmill"
                  className="animate-png"
                  src={Particularite}
                  width="100"
                />
              </div>
              <h2>{text[0].title}</h2>
              <p onClick={() => Descriptif(0)}>En savoir plus...</p>
            </article>
            <article>
              <div
                style={{ backgroundColor: "#E8832D" }}
                className="img-container"
              >
                <img
                  alt="diploma"
                  className="animate-png"
                  src={Diploma}
                  width="100"
                />
              </div>
              <h2>{text[1].title}</h2>
              <p onClick={() => Descriptif(1)}>En savoir plus...</p>
            </article>
            <article>
              <div
                style={{ backgroundColor: "#62DBFB" }}
                className="img-container"
              >
                <img
                  alt="paris map"
                  className="animate-png"
                  src={Phone}
                  width="100"
                />
              </div>
              <h2>{text[2].title}</h2>
              <p onClick={() => Descriptif(2)}>En savoir plus...</p>
            </article>
          </div>
        </section>

        <section className="presentation-slider s85">
          <div className="wrapper-presentation-title">
            <article
              className={selected === 0 ? "selected" : ""}
              onClick={() => setSelected(0)}
            >
              <p>Offres sportives adaptées</p>
            </article>
            <article
              className={selected === 1 ? "selected" : ""}
              onClick={() => setSelected(1)}
            >
              <p>Investissement dans le bien-être</p>
            </article>
            <article
              className={selected === 2 ? "selected" : ""}
              onClick={() => setSelected(2)}
            >
              <p>Amélioration de la cohésion</p>
            </article>
          </div>
          <div className="wrapper-presentation-title">
            <article
              className={selected === 3 ? "selected" : ""}
              onClick={() => setSelected(3)}
            >
              <p>1 - Partenariat</p>
            </article>
            <article
              className={selected === 4 ? "selected" : ""}
              onClick={() => setSelected(4)}
            >
              <p>2 - Communication à vos équipes</p>
            </article>
            <article
              className={selected === 5 ? "selected" : ""}
              onClick={() => setSelected(5)}
            >
              <p>3 - Inscription de vos employés</p>
            </article>
          </div>
          <div className="presentation-texte">
            <img
              src={presentation[selected].image}
              alt="presentation studio quatre"
            />
            <p>{presentation[selected].text}</p>
          </div>
          <img src={Line2} alt="triple line ui/ux" className="img-line" />
        </section>

        <section className="presentation-coach app-section s85">
          <div>
            <p>STUDIO QUATRE</p>
            <h2>RÉSERVEZ DES SÉANCES DE SPORT</h2>
            <p className="under-p-title">
              Utilisez votre iPhone, iPad ou votre Android pour réserver des
              séances de sport
            </p>
            <div className="button-dw" onClick={downloadApp}>
              <p>Téléchargez l'application</p>
              <div className="play-btn">
                {getMobileOS() === "Android" ? (
                  <Icon
                    className="phone-logo"
                    style={{ top: "4px" }}
                    icon="ant-design:android-filled"
                    width={48}
                  />
                ) : getMobileOS() === "iOS" ? (
                  <Icon
                    className="phone-logo"
                    style={{ top: "4px" }}
                    icon="logos:apple"
                    width={30}
                  />
                ) : (
                  <Icon
                    className="phone-logo"
                    style={{ top: "4px" }}
                    icon="ic:baseline-qrcode"
                    width={48}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mockup-div">
            <img
              alt="mockup studio quatre app iphone & android"
              src={Mockup}
              className="bg-removing"
            />
          </div>
        </section>

        <section class="slider-infinite">
          <h2 className="text afh1e">
            Ils nous ont fait{" "}
            <span style={{ backgroundColor: "#7123FA", color: "white" }}>
              confiance !
            </span>
          </h2>
          <div class="slide-track">
            <div class="slide">
              <img src="img/logos/aso.png" width={230} height={130} alt="aso" />
            </div>
            <div class="slide">
              <img
                src="img/logos/arcdeseine.png"
                width={230}
                height={130}
                alt="arc de seine"
              />
            </div>
            <div class="slide">
              <img src="img/logos/bic.png" width={230} height={130} alt="bic" />
            </div>
            <div class="slide">
              <img
                src="img/logos/cafesrichard.png"
                width={230}
                height={130}
                alt="cafés richard"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/cbre.png"
                width={230}
                height={130}
                alt="cbre"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/gosport.jpg"
                width={230}
                height={130}
                alt="gosport"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/hermes.png"
                width={230}
                height={130}
                alt="hermes"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/nafnaf.png"
                width={230}
                height={130}
                alt="nafnaf"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/netapp.png"
                width={230}
                height={130}
                alt="netapp"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/solvay.png"
                width={230}
                height={130}
                alt="solvay"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/sonepar.png"
                width={230}
                height={130}
                alt="sonepar"
              />
            </div>
            <div class="slide">
              <img
                src="img/logos/eam-anne-bergunion.png"
                width={230}
                height={130}
                alt="eam"
              />
            </div>
          </div>
          <section className="avantages-wrapper">
          <article className="avantages-bloc">
            <div>
              <img src="img/money.svg" width={50} height={50} alt="money" />
              <p>Avantages fiscaux</p>
            </div>
            <p className="morphtext">
              Le sport en entreprise peut vous faire bénéficier d'exemptions de
              charges salariales et patronales.
            </p>
          </article>

          <article className="avantages-bloc">
            <div>
              <img src="img/runner.svg" width={50} height={50} alt="runner" />
              <p>Marque employeur</p>
            </div>
            <p className="morphtext">
              Proposer un avantage sport et bien-être à vos collaborateurs
              supporte l'attraction ainsi que la rétention des talents. C'est
              également un élément d'amplification et de développement pour
              marque employeur forte.
            </p>
          </article>
        </section>
        </section>
        <Contact />
      </main>
      <Footer />
    </>
  );
}
