import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { io } from "socket.io-client";

const socket = io('https://lestudioquatre.com');
export default function ChatFrame() {

    const { id } = useParams();
    const [csrf, setCSRF] = useState('')
    const [message, setMessage] = useState('')
    const [chat, setChat] = useState([])



    useEffect(() => {
        socket.on(id, (name, message) => {
            const timestamp = Math.floor(Date.now() / 1000);
            setChat((prevChat) => [
                ...prevChat,
                { name, message, timestamp },
            ]);
        });
        return () => {
            socket.disconnect();
        };
    }, [id]);

    useEffect(() => {
        axios.post("/admin/getcsrf")
            .then((data) => {
                if (!data.data.login) {
                    window.location.href = "/wsq700/dashboard"
                }

                setCSRF(data.data.csrf)
                axios.post('/admin/chat/frame/load', { csrf: data.data.csrf, id })
                    .then((response) => {
                        const chat = response.data[0]
                        chat.sort((a, b) => a.timestamp - b.timestamp);
                        setChat(chat)

                    })
                    .catch((err) => {

                        if (err?.request?.status === 429) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Trop de requêtes, veuillez réessayer plus tard.'
                            })
                        } else if (err?.response?.data?.msg) {
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.msg
                            })
                        } else {
                            Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                        }
                    })
            })
            .catch((err) => {
                if (err?.request?.status === 429) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Trop de requêtes, veuillez réessayer plus tard.'
                    })
                } else if (err?.response?.data?.msg) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.msg
                    })
                } else {
                    Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                }
            })
    }, [id])

    const handleNewMessage = async () => {
        axios.post('/admin/chat/frame/newMessage', { id, message, csrf })
            .then(() => { setMessage('') })
            .catch((err) => {
                if (err?.request?.status === 429) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Trop de requêtes, veuillez réessayer plus tard.'
                    })
                } else if (err?.response?.data?.msg) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.msg
                    })
                } else {
                    Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                }
            })
    }

    function timestampToDateTime(timestamp) {
        const date = new Date(timestamp * 1000);

        // Extract date components
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        // Extract time components
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Create the formatted date-time string
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;

        return formattedDateTime;
    }

    return (
        <>
            <div className="chat-frame">
                {
                    chat.map((item, index) => (
                        <article key={index}>
                            <h2>{item.name}</h2>
                            <p>{timestampToDateTime(item.timestamp)}</p>
                            <p>{item.message}</p>
                        </article>
                    ))
                }
            </div>
            <div className="input-chat">
                <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
                <button onClick={handleNewMessage}>Envoyer</button>
            </div>
        </>
    )
}