import { NavLink } from "react-router-dom";
import Menu from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function Mentions() {
  return (
    <>
      <Menu />
      <Helmet>
        <title>Mentions légales - STUDIO QUATRE</title>
        <link rel="canonical" href="https://lestudioquatre.com/mentions" />
        <meta
          name="description"
          content="Accéder dès maintenant à nos mentions légales"
        />
        <meta property="og:site_name" content="STUDIO QUATRE" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lestudioquatre.com/mentions" />
        <meta property="og:title" content="STUDIO QUATRE - Mentions légales" />
        <meta
          property="og:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          property="og:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@studioquatre" />
        <meta name="twitter:title" content="STUDIO QUATRE - Mentions légales" />
        <meta
          name="twitter:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          name="twitter:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta
          name="twitter:url"
          content="https://lestudioquatre.com/mentions"
        />
      </Helmet>
      <my-reasonably-long-component>
        <main className="mentions">
          <article className="block-legals def">
            <h2>Définitions</h2>
            <p>
              <b>Client :</b> tout professionnel ou personne physique capable au
              sens des articles 1123 et suivants du Code civil, ou personne
              morale, qui visite le Site objet des présentes conditions
              générales.
              <br />
              <b>Prestations et Services :</b>{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> met à
              disposition des Clients :
            </p>
            <p>
              <b>Contenu :</b> Ensemble des éléments constituants l’information
              présente sur le Site, notamment textes – images – vidéos.
            </p>
            <p>
              <b>Informations clients :</b> Ci après dénommé « Information (s) »
              qui correspondent à l’ensemble des données personnelles
              susceptibles d’être détenues par{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> pour la
              gestion de votre compte, de la gestion de la relation client et à
              des fins d’analyses et de statistiques.
            </p>
            <p>
              <b>Utilisateur :</b> Internaute se connectant, utilisant le site
              susnommé.
            </p>
            <p>
              <b>Informations personnelles :</b> « Les informations qui
              permettent, sous quelque forme que ce soit, directement ou non,
              l'identification des personnes physiques auxquelles elles
              s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
            </p>
            <p>
              Les termes « données à caractère personnel », « personne concernée
              », « sous traitant » et « données sensibles » ont le sens défini
              par le Règlement Général sur la Protection des Données (RGPD : n°
              2016-679)
            </p>
          </article>
          <article className="block-legals">
            <h2>1. Présentation du site internet.</h2>
            <p>
              En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour
              la confiance dans l'économie numérique, il est précisé aux
              utilisateurs du site internet{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> l'identité
              des différents intervenants dans le cadre de sa réalisation et de
              son suivi:{" "}
            </p>
            <p>
              <strong>Propriétaire</strong> : SIRET : 84463014500010 STUDIO
              QUATRE Capital social de 0€ Numéro de TVA: FR90844630145 – 4 rue
              Denfert Rochereau 92600 Asnières sur Seine
              <br />
              <strong>Responsable publication</strong> : THENARD Johnny
              <br />
              Le responsable publication est une personne physique ou une
              personne morale.
              <br />
              <strong>Webmaster</strong> : Thenard Logan
              <br />
              <strong>Hébergeur</strong> : o2switch – Chem. des Pardiaux, 63000
              Clermont-Ferrand
              <br />
            </p>
          </article>
          <article className="block-legals">
            <h2>
              2. Conditions générales d’utilisation du site et des services
              proposés.
            </h2>
            <p>
              Le Site constitue une œuvre de l’esprit protégée par les
              dispositions du Code de la Propriété Intellectuelle et des
              Réglementations Internationales applicables. Le Client ne peut en
              aucune manière réutiliser, céder ou exploiter pour son propre
              compte tout ou partie des éléments ou travaux du Site.
            </p>
            <p>
              L’utilisation du site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> implique
              l’acceptation pleine et entière des conditions générales
              d’utilisation ci-après décrites. Ces conditions d’utilisation sont
              susceptibles d’être modifiées ou complétées à tout moment, les
              utilisateurs du site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> sont donc
              invités à les consulter de manière régulière.
            </p>
            <p>
              Ce site internet est normalement accessible à tout moment aux
              utilisateurs. Une interruption pour raison de maintenance
              technique peut être toutefois décidée par{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink>, qui
              s’efforcera alors de communiquer préalablement aux utilisateurs
              les dates et heures de l’intervention. Le site web{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> est mis à
              jour régulièrement par <NavLink to="/"></NavLink> responsable. De
              la même façon, les mentions légales peuvent être modifiées à tout
              moment : elles s’imposent néanmoins à l’utilisateur qui est invité
              à s’y référer le plus souvent possible afin d’en prendre
              connaissance.
            </p>
          </article>
          <article className="block-legals">
            <h2>3. Description des services fournis.</h2>
            <p>
              Le site internet{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> a pour objet
              de fournir une information concernant l’ensemble des activités de
              la société. <NavLink to="/">https://lestudioquatre.com</NavLink>{" "}
              s’efforce de fournir sur le site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> des
              informations aussi précises que possible. Toutefois, il ne pourra
              être tenu responsable des oublis, des inexactitudes et des
              carences dans la mise à jour, qu’elles soient de son fait ou du
              fait des tiers partenaires qui lui fournissent ces informations.
            </p>
            <p>
              Toutes les informations indiquées sur le site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> sont données
              à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs,
              les renseignements figurant sur le site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> ne sont pas
              exhaustifs. Ils sont donnés sous réserve de modifications ayant
              été apportées depuis leur mise en ligne.
            </p>
          </article>
          <article className="block-legals">
            <h2>4. Limitations contractuelles sur les données techniques.</h2>
            <p>
              Le site utilise la technologie JavaScript. Le site Internet ne
              pourra être tenu responsable de dommages matériels liés à
              l’utilisation du site. De plus, l’utilisateur du site s’engage à
              accéder au site en utilisant un matériel récent, ne contenant pas
              de virus et avec un navigateur de dernière génération mis-à-jour
              Le site <NavLink to="/">https://lestudioquatre.com</NavLink> est
              hébergé chez un prestataire sur le territoire de l’Union
              Européenne conformément aux dispositions du Règlement Général sur
              la Protection des Données (RGPD : n° 2016-679)
            </p>
            <p>
              L’objectif est d’apporter une prestation qui assure le meilleur
              taux d’accessibilité. L’hébergeur assure la continuité de son
              service 24 Heures sur 24, tous les jours de l’année. Il se réserve
              néanmoins la possibilité d’interrompre le service d’hébergement
              pour les durées les plus courtes possibles notamment à des fins de
              maintenance, d’amélioration de ses infrastructures, de défaillance
              de ses infrastructures ou si les Prestations et Services génèrent
              un trafic réputé anormal.
            </p>
            <p>
              <NavLink to="/">https://lestudioquatre.com</NavLink> et
              l’hébergeur ne pourront être tenus responsables en cas de
              dysfonctionnement du réseau Internet, des lignes téléphoniques ou
              du matériel informatique et de téléphonie lié notamment à
              l’encombrement du réseau empêchant l’accès au serveur.
            </p>
          </article>
          <article className="block-legals">
            <h2>5. Propriété intellectuelle et contrefaçons.</h2>
            <p>
              Nous sommes susceptible d'utiliser des images de type dessin
              provenant de <a href="https://www.flaticon.com">Flaticon</a> et
              également <a href="https://www.pngtree.com">PNGTree</a>.
            </p>
            <p>
              <NavLink to="/">https://lestudioquatre.com</NavLink> est
              propriétaire des droits de propriété intellectuelle et détient les
              droits d’usage sur tous les éléments accessibles sur le site
              internet, notamment les textes, images, graphismes, logos, vidéos,
              icônes et sons. Toute reproduction, représentation, modification,
              publication, adaptation de tout ou partie des éléments du site,
              quel que soit le moyen ou le procédé utilisé, est interdite, sauf
              autorisation écrite préalable de : THENARD Johnny.
            </p>
            <p>
              Toute exploitation non autorisée du site ou de l’un quelconque des
              éléments qu’il contient sera considérée comme constitutive d’une
              contrefaçon et poursuivie conformément aux dispositions des
              articles L.335-2 et suivants du Code de Propriété Intellectuelle.
            </p>
          </article>
          <article className="block-legals">
            <h2>6. Limitations de responsabilité.</h2>
            <p>
              <NavLink to="/">https://lestudioquatre.com</NavLink> agit en tant
              qu’éditeur du site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> est
              responsable de la qualité et de la véracité du Contenu qu’il
              publie.
            </p>
            <p>
              <NavLink to="/">https://lestudioquatre.com</NavLink> ne pourra
              être tenu responsable des dommages directs et indirects causés au
              matériel de l’utilisateur, lors de l’accès au site internet{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink>, et résultant
              soit de l’utilisation d’un matériel ne répondant pas aux
              spécifications indiquées au point 4, soit de l’apparition d’un bug
              ou d’une incompatibilité.
            </p>
          </article>
          <article className="block-legals">
            <h2>
              7. Liens hypertextes « cookies » et balises (“tags”) internet
            </h2>
            <p>
              {" "}
              Le site <NavLink to="/">https://lestudioquatre.com</NavLink>{" "}
              contient un certain nombre de liens hypertextes vers d’autres
              sites, mis en place avec l’autorisation de{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink>. Cependant,{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> n’a pas la
              possibilité de vérifier le contenu des sites ainsi visités, et
              n’assumera en conséquence aucune responsabilité de ce fait.
            </p>
            <p>
              Sauf si vous décidez de désactiver les cookies, vous acceptez que
              le site puisse les utiliser. Vous pouvez à tout moment désactiver
              ces cookies et ce gratuitement à partir des possibilités de
              désactivation qui vous sont offertes et rappelées ci-après,
              sachant que cela peut réduire ou empêcher l’accessibilité à tout
              ou partie des Services proposés par le site.
            </p>
            <p />
            <h2>7.1. « COOKIES »</h2>
            <p>
              {" "}
              Un « cookie » est un petit fichier d’information envoyé sur le
              navigateur de l’Utilisateur et enregistré au sein du terminal de
              l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies
              »). Ce fichier comprend des informations telles que le nom de
              domaine de l’Utilisateur, le fournisseur d’accès Internet de
              l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi
              que la date et l’heure d’accès. Les Cookies ne risquent en aucun
              cas d’endommager le terminal de l’Utilisateur.
            </p>
            <p>
              {" "}
              Les Cookies facilitant la navigation et/ou la fourniture des
              services proposés par le Site, l’Utilisateur peut configurer son
              navigateur pour qu’il lui permette de décider s’il souhaite ou non
              les accepter de manière à ce que des Cookies soient enregistrés
              dans le terminal ou, au contraire, qu’ils soient rejetés, soit
              systématiquement, soit selon leur émetteur. L’Utilisateur peut
              également configurer son logiciel de navigation de manière à ce
              que l’acceptation ou le refus des Cookies lui soient proposés
              ponctuellement, avant qu’un Cookie soit susceptible d’être
              enregistré dans son terminal.{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> informe
              l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités
              de son logiciel de navigation ne soient pas toutes disponibles.
            </p>
            <p>
              {" "}
              À tout moment, l’Utilisateur peut faire le choix d’exprimer et de
              modifier ses souhaits en matière de Cookies.{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> pourra en
              outre faire appel aux services de prestataires externes pour
              l’aider à recueillir et traiter les informations décrites dans
              cette section.
            </p>
            <h2 style={{ marginTop: "10px" }}>
              Article 7.2. BALISES (“TAGS”) INTERNET
            </h2>
            <p>
              {" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> peut employer
              occasionnellement des balises Internet (également appelées « tags
              », ou balises d’action, GIF à un pixel, GIF transparents, GIF
              invisibles et GIF un à un) et les déployer par l’intermédiaire
              d’un partenaire spécialiste d’analyses Web susceptible de se
              trouver (et donc de stocker les informations correspondantes, y
              compris l’adresse IP de l’Utilisateur) dans un pays étranger.
            </p>
            <p>
              {" "}
              Cette technologie permet à{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> d’évaluer les
              réponses des visiteurs face au Site et l’efficacité de ses actions
              (par exemple, le nombre de fois où une page est ouverte et les
              informations consultées), ainsi que l’utilisation de ce Site par
              l’Utilisateur.{" "}
            </p>
            <p>
              {" "}
              Le prestataire externe pourra éventuellement recueillir des
              informations sur les visiteurs du Site et d’autres sites Internet
              grâce à ces balises, constituer des rapports sur l’activité du
              Site à l’attention de{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink>, et fournir
              d’autres services relatifs à l’utilisation de celui-ci et
              d’Internet.
            </p>
            <p> </p>
          </article>
          <article className="block-legals">
            <h2>8. Droit applicable et attribution de juridiction.</h2>
            <p>
              {" "}
              Tout litige en relation avec l’utilisation du site{" "}
              <NavLink to="/">https://lestudioquatre.com</NavLink> est soumis au
              droit français.
            </p>
          </article>
        </main>
      </my-reasonably-long-component>
      <Footer />
    </>
  );
}
