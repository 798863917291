import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";

export default function HomeAdmin() {

    const reCaptchaRef = useRef();

    const [captcha, setCaptcha] = useState("");
    const [Email, setEmail] = useState("")
    const HandleEmail = (e) => setEmail(e.target.value)
    const [Password, setPassword] = useState("")
    const HandlePassword = (e) => setPassword(e.target.value)
    const [Auth, setAuth] = useState("")
    const HandleAuth = (e) => setAuth(e.target.value)

    const [CSRF, setCSRF] = useState("")

    useEffect(() => {
        axios.post("/admin/getcsrf")
            .then((data) => {
                if (data.data.login) {
                    window.location.href = "/wsq700/dashboard"
                }

                setCSRF(data.data.csrf)
                var loader = document.getElementById("loader");
                loader.classList.add('nodisplay')
            })
            .catch((err) => {
                if (err?.request?.status === 429) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Trop de requêtes, veuillez réessayer plus tard.'
                    })
                } else if (err?.response?.data?.msg) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.msg
                    })
                } else {
                    Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                }
            })
    }, [])

    const Submit = (e) => {
        e.preventDefault()

        if (!Email || !Password || !Auth || !CSRF || !captcha) {
            return;
        }

        const params = {
            email: Email,
            password: Password,
            auth: Auth,
            csrf: CSRF,
            captcha: captcha,
        }

        axios.post("/admin/login", params)
            .then(() => {
                window.location.href = "/wsq700/dashboard"
            })
            .catch((err) => {
                if (err?.request?.status === 429) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Trop de requêtes, veuillez réessayer plus tard.'
                    })
                } else if (err?.response?.data?.msg) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.msg
                    })
                } else {
                    Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                }
            })
    }


    useEffect(() => {
        const loadReCaptchaScript = () => {
            const script = document.createElement('script');
            script.src = "https://www.google.com/recaptcha/api.js?render=6LeA6NAnAAAAAD2t4RCJHZTaaDVG0SYpgP1ofVo3";
            script.onload = handleReCaptchaLoad;
            document.body.appendChild(script);
        };

        const handleReCaptchaLoad = () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LeA6NAnAAAAAD2t4RCJHZTaaDVG0SYpgP1ofVo3')
                    .then((token) => {
                        setCaptcha(token)
                    })
            });
        };

        loadReCaptchaScript();
    }, []);


    return (
        <>
            <Helmet>
                <title>Admin Side - STUDIO QUATRE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div
                className="g-recaptcha"
                data-sitekey="6LeOrkMnAAAAABueBdGN0lU86acdem2F3egBK47G"
                data-size="invisible"
                ref={reCaptchaRef}
            >
            </div>
            <div id="loader" className="hiuxxw loaderPay">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                <h2 style={{ color: "white", textAlign: "center" }}>Veuillez patienter</h2>
            </div>
            <form onSubmit={Submit}>
                <div className="form-element adminForm">
                    <div className="titlebox9">Connexion à l'espace personnel</div>
                    <div className="user-box">
                        <input value={Email} onChange={HandleEmail} type="text" name="email" required /><br />
                        <label htmlFor="email">Adresse E-mail</label>
                    </div>
                    <div className="user-box">
                        <input value={Password} onChange={HandlePassword} type="password" name="password" required />
                        <label htmlFor="password">Mot de passe</label>
                    </div>
                    <div className="user-box">
                        <input value={Auth} onChange={HandleAuth} type="code" name="code" required />
                        <input type="hidden" name="csrf-token" value={CSRF} />
                        <label htmlFor="code">2FA</label>
                    </div>
                    <button className="gingerlab" type="submit">Se connecter</button>
                </div>
            </form>

        </>
    );
}