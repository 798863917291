import { useNavigate } from "react-router-dom";
import Menu from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

export default function PhilosophieDuStudio() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Philosophie du Studio - STUDIO QUATRE</title>
        <meta
          name="description"
          content="Nous avons tous envie d'être en forme et de nous sentir bien, mais ce n'est pas toujours facile, surtout après cette période de fermeture des salles de sport, c'est pour cela que nous..."
        />
        <meta property="og:site_name" content="STUDIO QUATRE" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://lestudioquatre.com/philosophie-du-studio"
        />
        <meta
          property="og:title"
          content="Philosophie du Studio - STUDIO QUATRE"
        />
        <meta
          property="og:description"
          content="Nous avons tous envie d'être en forme et de nous sentir bien, mais ce n'est pas toujours facile, surtout après cette période de fermeture des salles de sport, c'est pour cela que nous..."
        />
        <meta
          property="og:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@studioquatre" />
        <meta
          name="twitter:title"
          content="Philosophie du Studio - STUDIO QUATRE"
        />
        <meta
          name="twitter:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          name="twitter:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta
          name="twitter:url"
          content="https://lestudioquatre.com/philosophie-du-studio"
        />
        <link
          rel="canonical"
          href="https://lestudioquatre.com/philosophie-du-studio"
        />
      </Helmet>
      <Menu />
      <main>
        <section className="blog--post">
          <p className="h1offer" style={{ fontSize: 13 }}>
            <span style={{ padding: 0 }}>
              <button
                style={{
                  color: "#000",
                  fontWeight: 900,
                  background: "none",
                  cursor: "pointer",
                  padding: "5px",
                }}
                onClick={() => navigate(-1)}
              >
                Retour
              </button>{" "}
              /{" "}
              <a style={{ color: "#000", fontWeight: 900 }} href="blog">
                Blog
              </a>{" "}
              / Philosophie du Studio &nbsp;
            </span>
          </p>
          <h1 className="blog--post-h1">Philosophie du studio</h1>
          <article className="blog--post-p">
            <h2>Écrit par Le Jemsky le 28 décembre 2021.</h2>
          </article>
          <div className="articlepost">
            <p>
              Nous avons tous envie d'être en forme et de nous sentir bien, mais
              ce n'est pas toujours facile, surtout après cette période de
              fermeture des salles de sport, c'est pour cela que nous proposons
              d'atteindre tous vos objectifs en un temps-record, mais en y
              ajoutant détente et plaisir et pour ceux ou celles qui le
              souhaiteraient de la méditation, et mes clients adorent ! Les
              méthodes que nous utilisons chez Studio Quatre font notre
              particularité. Nous repèrons ce qui est essentiel pour vous, et
              élaborons un programme sur mesure. Nous avons tout ce dont vous
              pouvez rêver : le savoir-faire, l'expérience et l'équipement.
              N'attendez plus rejoignez Studio Quatre.
            </p>
          </div>
          <img
            src="img/a/lsqpoids.webp"
            width={1270}
            height={715}
            className="blog--img"
            alt="lsqpoids"
          />
        </section>
      </main>
      <Footer />
    </>
  );
}
