import Menu from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function CGU() {
  return (
    <>
      <Helmet>
        <title>Condition générales de vente - STUDIO QUATRE</title>
        <meta
          name="description"
          content="Retrouvez ici nos conditions générales d'utilisations"
        />
        <link rel="canonical" href="https://lestudioquatre.com/cgu" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Menu />
      <main className="mentions">
        <article className="block-legals def">
          <h2>Définitions</h2>
          <p>
            L'Éditeur : La personne, physique ou morale, qui édite les services
            de communication au public en ligne. Le Site : L'ensemble des sites,
            pages Internet et services en ligne proposés par l'Éditeur.
            L'Utilisateur : La personne utilisant le Site et les services.
          </p>
        </article>
        <article className="block-legals">
          <h2>1. Nature des données collectées</h2>
          <p>
            Dans le cadre de l'utilisation des Sites, l'Éditeur est susceptible
            de collecter les catégories de données suivantes concernant ses
            Utilisateurs : Données de connexion (adresses IP, journaux
            d'événements...){" "}
          </p>
        </article>
        <article className="block-legals">
          <h2>2. Communication des données personnelles à des tiers</h2>
          <p>
            Vos données ne font l'objet d'aucune communication à des tiers. Vous
            êtes toutefois informés qu'elles pourront être divulguées en
            application d'une loi, d'un règlement ou en vertu d'une décision
            d'une autorité réglementaire ou judiciaire compétente.
          </p>
        </article>
        <article className="block-legals">
          <h2>
            3. Information préalable pour la communication des données
            personnelles à des tiers en cas de fusion / absorption
          </h2>
          <p>
            Collecte de l’opt-in (consentement) préalable à la transmission des
            données suite à une fusion / acquisition
          </p>
          <p>
            Dans le cas où nous prendrions part à une opération de fusion,
            d’acquisition ou à toute autre forme de cession d’actifs, nous nous
            engageons à obtenir votre consentement préalable à la transmission
            de vos données personnelles et à maintenir le niveau de
            confidentialité de vos données personnelles auquel vous avez
            consenti.
          </p>
        </article>
        <article className="block-legals">
          <h2>4. Agrégation des données</h2>
          <p>
            <strong>Agrégation avec des données non personnelles</strong>
          </p>
          <p>
            Nous pouvons publier, divulguer et utiliser les informations
            agrégées (informations relatives à tous nos Utilisateurs ou à des
            groupes ou catégories spécifiques d'Utilisateurs que nous combinons
            de manière à ce qu'un Utilisateur individuel ne puisse plus être
            identifié ou mentionné) et les informations non personnelles à des
            fins d'analyse du secteur et du marché, de profilage démographique,
            à des fins promotionnelles et publicitaires et à d'autres fins
            commerciales.
          </p>
        </article>
        <article className="block-legals">
          <h2>5. Collecte des données d'identité</h2>
          <p>
            <strong>Consultation libre</strong>
          </p>
          <p>
            La consultation du Site ne nécessite pas d'inscription ni
            d'identification préalable. Elle peut s'effectuer sans que vous ne
            communiquiez de données nominatives vous concernant (nom, prénom,
            adresse, etc). Nous ne procédons à aucun enregistrement de données
            nominatives pour la simple consultation du Site.
          </p>
        </article>
        <article className="block-legals">
          <h2>6. Collecte des données d'identification</h2>
          <p>
            Utilisation de l'identifiant de l'utilisateur exclusivement pour
            l'accès aux services. Nous n'utilisons vos identifiants
            électroniques que pendant l'exécution du contrat.
          </p>
        </article>
        <article className="block-legals">
          <h2>7. Collecte des données du terminal</h2>
          <p>
            <strong>
              Collecte des données de profilage et des données techniques à des
              fins de fourniture du service
            </strong>
          </p>
          <p>
            Certaines des données techniques de votre appareil sont collectées
            automatiquement par le Site. Ces informations incluent notamment
            votre adresse IP, fournisseur d'accès à Internet, configuration
            matérielle, configuration logicielle... La collecte de ces données
            est nécessaire à la fourniture des services.
          </p>
          <p>
            <strong>
              Collecte des données techniques à des fins publicitaires,
              commerciales et statistiques
            </strong>
          </p>
          <p>
            Les données techniques de votre appareil sont automatiquement
            collectées et enregistrées par le Site, à des fins statistiques. Ces
            informations nous aident à personnaliser et à améliorer
            continuellement votre expérience sur notre Site. Nous ne collectons
            ni ne conservons aucune donnée nominative (nom, prénom, adresse...)
            éventuellement attachée à une donnée technique. Les données
            collectées ne seront pas revendues à des tiers.
          </p>
        </article>
        <article className="block-legals">
          <h2>8. Cookies</h2>
          <p>
            <strong>Durée de conservation des cookies</strong>
          </p>
          <p>
            Conformément aux recommandations de la CNIL, la durée maximale de
            conservation des cookies est de 13 mois au maximum après leur
            premier dépôt dans le terminal de l'Utilisateur, tout comme la durée
            de la validité du consentement de l’Utilisateur à l’utilisation de
            ces cookies. La durée de vie des cookies n’est pas prolongée à
            chaque visite. Le consentement de l’Utilisateur devra donc être
            renouvelé à l'issue de ce délai.
          </p>
          <p>
            <strong>Finalité cookies</strong>
          </p>
          <p>
            Les cookies peuvent être utilisés pour des fins statistiques
            notamment pour optimiser les services rendus à l'Utilisateur, à
            partir du traitement des informations concernant la fréquence
            d'accès, la personnalisation des pages ainsi que les opérations
            réalisées et les informations consultées. Vous êtes informé que
            l'Éditeur est susceptible de déposer des cookies sur votre terminal.
            Le cookie enregistre des informations relatives à la navigation sur
            le service (les pages que vous avez consultées, la date et l'heure
            de la consultation...) que nous pourrons lire lors de vos visites
            ultérieures.
          </p>
          <p>
            <strong>Droit de l'Utilisateur de refuser les cookies</strong>
          </p>
          <p>
            Vous reconnaissez avoir été informé que l'Éditeur peut avoir recours
            à des cookies. Si vous ne souhaitez pas que des cookies soient
            utilisés sur votre terminal, la plupart des navigateurs vous
            permettent de désactiver les cookies en passant par les options de
            réglage.
          </p>
        </article>
        <article className="block-legals">
          <h2>9. Conservation des données techniques</h2>
          <p>
            <strong>Durée de conservation des données techniques</strong>
          </p>
          <p>
            Les données techniques sont conservées pour la durée strictement
            nécessaire à la réalisation des finalités visées ci-avant.
          </p>
        </article>
        <article className="block-legals">
          <h2>
            10. Délai de conservation des données personnelles et
            d'anonymisation
          </h2>
          <p>
            <strong>
              Conservation des données pendant la durée de la relation
              contractuelle
            </strong>
          </p>
          <p>
            Conformément à l'article 6-5° de la loi n°78-17 du 6 janvier 1978
            relative à l'informatique, aux fichiers et aux libertés, les données
            à caractère personnel faisant l'objet d'un traitement ne sont pas
            conservées au-delà du temps nécessaire à l'exécution des obligations
            définies lors de la conclusion du contrat ou de la durée prédéfinie
            de la relation contractuelle
          </p>
          <p>
            <strong>
              Conservation des données anonymisées au delà de la relation
              contractuelle / après la suppression du compte
            </strong>
          </p>
          <p>
            Nous conservons les données personnelles pour la durée strictement
            nécessaire à la réalisation des finalités décrites dans les
            présentes Politiques de confidentialité. Au-delà de cette durée,
            elles seront anonymisées et conservées à des fins exclusivement
            statistiques et ne donneront lieu à aucune exploitation, de quelque
            nature que ce soit.
          </p>
          <p>
            <strong>Suppression des données après suppression du compte</strong>
          </p>
          <p>
            Des moyens de purge de données sont mis en place afin d'en prévoir
            la suppression effective dès lors que la durée de conservation ou
            d'archivage nécessaire à l'accomplissement des finalités déterminées
            ou imposées est atteinte. Conformément à la loi n°78-17 du 6 janvier
            1978 relative à l'informatique, aux fichiers et aux libertés, vous
            disposez par ailleurs d'un droit de suppression sur vos données que
            vous pouvez exercer à tout moment en prenant contact avec l'Éditeur.
          </p>
        </article>
        <article className="block-legals">
          <h2>11. Suppression du compte</h2>
          <p>
            <strong>Suppression du compte à la demande</strong>
          </p>
          <p>
            L'Utilisateur a la possibilité de supprimer son Compte à tout
            moment, par simple demande à l'Éditeur.
          </p>
          <p>
            <strong>
              Suppression du compte en cas de violation de la Politique de
              Confidentialité
            </strong>
          </p>
          <p>
            En cas de violation d'une ou de plusieurs dispositions de la
            Politique de Confidentialité ou de tout autre document incorporé aux
            présentes par référence, l'Éditeur se réserve le droit de mettre fin
            ou restreindre sans aucun avertissement préalable et à sa seule
            discrétion, votre usage et accès aux services, à votre compte et à
            tous les Sites.
          </p>
        </article>
        <article className="block-legals">
          <h2>
            12. Indications en cas de faille de sécurité décelée par l'Éditeur
          </h2>
          <p>
            <strong>Durée de conservation des données techniques</strong>
          </p>
          <p>
            Nous nous engageons à mettre en oeuvre toutes les mesures techniques
            et organisationnelles appropriées afin de garantir un niveau de
            sécurité adapté au regard des risques d'accès accidentels, non
            autorisés ou illégaux, de divulgation, d'altération, de perte ou
            encore de destruction des données personnelles vous concernant. Dans
            l'éventualité où nous prendrions connaissance d'un accès illégal aux
            données personnelles vous concernant stockées sur nos serveurs ou
            ceux de nos prestataires, ou d'un accès non autorisé ayant pour
            conséquence la réalisation des risques identifiés ci-dessus, nous
            nous engageons à : Vous notifier l'incident dans les plus brefs
            délais ; Examiner les causes de l'incident et vous en informer ;
            Prendre les mesures nécessaires dans la limite du raisonnable afin
            d'amoindrir les effets négatifs et préjudices pouvant résulter dudit
            incident.
          </p>
          <p>
            <strong>Limitation de la responsabilité</strong>
          </p>
          <p>
            En aucun cas les engagements définis au point ci-dessus relatifs à
            la notification en cas de faille de sécurité ne peuvent être
            assimilés à une quelconque reconnaissance de faute ou de
            responsabilité quant à la survenance de l'incident en question.
          </p>
        </article>
        <article className="block-legals">
          <h2>13. Pas de transfert en dehors de l'Union européenne</h2>
          <p>
            <strong>Durée de conservation des données techniques</strong>
          </p>
          <p>
            L'Éditeur s'engage à ne pas transférer les données personnelles de
            ses Utilisateurs en dehors de l'Union européenne.
          </p>
        </article>
        <article className="block-legals">
          <h2>14. Modification de la politique de confidentialité</h2>
          <p>
            <strong>
              En cas de modification de la présente Politique de
              Confidentialité, engagement de ne pas baisser le niveau de
              confidentialité de manière substantielle sans l'information
              préalable des personnes concernées
            </strong>
          </p>
          <p>
            Nous nous engageons à vous informer en cas de modification
            substantielle de la présente Politique de Confidentialité, et à ne
            pas baisser le niveau de confidentialité de vos données de manière
            substantielle sans vous en informer et obtenir votre consentement.
          </p>
        </article>
        <article className="block-legals">
          <h2>15. Droit applicable et modalités de recours</h2>
          <p>
            <strong>Clause d'arbitrage</strong>
          </p>
          <p>
            Vous acceptez expressément que tout litige susceptible de naître du
            fait de la présente Politique de Confidentialité, notamment de son
            interprétation ou de son exécution, relèvera d'une procédure
            d'arbitrage soumise au règlement de la plateforme d'arbitrage
            choisie d'un commun accord, auquel vous adhérerez sans réserve.
          </p>
        </article>
        <article className="block-legals">
          <h2>16. Portabilité des données</h2>
          <p>
            <strong>Portabilité des données </strong>
          </p>
          <p>
            L'Éditeur s'engage à vous offrir la possibilité de vous faire
            restituer l'ensemble des données vous concernant sur simple demande.
            L'Utilisateur se voit ainsi garantir une meilleure maîtrise de ses
            données, et garde la possibilité de les réutiliser. Ces données
            devront être fournies dans un format ouvert et aisément
            réutilisable.{" "}
          </p>
        </article>
      </main>
      <Footer />
    </>
  );
}
