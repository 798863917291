import { Routes, Route, Navigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import Home from "./pages/Home";
import Meditrain from "./pages/Meditrain";
import Blog from "./pages/Blog";
import PhilosophieDuStudio from "./pages/Articles/Philosophie-du-studio";
import Mentions from "./pages/Mentions";
import CGU from "./pages/Cgu";

import "./css/navbar.css";
import "./css/home.css";
import "./css/meditrain.css";
import "./css/offres.css";
import "./css/programmes.css";
import "./css/boutique.css";
import "./css/blog.css";
import "./css/philosophie-du-studio.css";
import "./css/entreprises.css";
import "./css/contact.css";
import "./css/footer.css";
import "./css/PWAAmenagement.css";
import "./css/inscription.css";
import "./css/Admin.css";

// Le reste du Css parce que flemme un peu
import "./css/style.css";

import DashboardAdmin from "./pages/Admin/Dashboard";
import HomeAdmin from "./pages/Admin/Home";
import StatistiquesAdmin from "./pages/Admin/Statistiques";
import Validation from "./pages/Admin/Validation";
import PrivateLink from "./pages/Admin/PrivateLink";
import Inscription from "./pages/Inscription";
import AdminChat from "./pages/Admin/Chat";
import ChatFrame from "./pages/Admin/ChatFrame";
import DownloadApp from "./components/DownloadApp";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=6466744865" />
      </Helmet>
      <CookieConsent
        buttonText="Ok"
        style={{ background: "#000" }}
        buttonStyle={{
          color: "black",
          background: "white",
          fontSize: "13px",
          borderRadius: "7px",
        }}
      >
        En naviguant sur ce site web, vous acceptez les cookies permettant
        d'améliorer votre expérience utilisateur
      </CookieConsent>
      <Routes>
        <Route path="/*" element={<Navigate to="/" />} />
        <Route path="/" element={<Home />} />
        <Route path="/meditrain" element={<Meditrain />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/philosophie-du-studio"
          element={<PhilosophieDuStudio />}
        />
        <Route path="/confidentialite" element={<CGU />} />
        <Route path="/mentions" element={<Mentions />} />
        <Route path="/inscription/:id" element={<Inscription />} />
        <Route path="/download" element={<DownloadApp />} />

        {}

        {
          // Admin
        }

        <Route path="/wsq700/" element={<HomeAdmin />} />
        <Route path="/wsq700/dashboard" element={<DashboardAdmin />} />
        <Route path="/wsq700/stats" element={<StatistiquesAdmin />} />
        <Route path="/wsq700/validation" element={<Validation />} />
        <Route path="/wsq700/privatelinks" element={<PrivateLink />} />
        <Route path="/wsq700/chat" element={<AdminChat />} />
        <Route path="/wsq700/chat/frame/:id" element={<ChatFrame />} />
      </Routes>
    </>
  );
}

export default App;
