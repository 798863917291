import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { decode } from "html-entities";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function Validation() {
  const [Resa, setResa] = useState([]);
  const [CSRF, setCSRF] = useState("");

  useEffect(() => {
    axios
      .post("/admin/getcsrf")
      .then((data) => {
        if (!data.data.login) {
          window.location.href = "/wsq700/";
        }

        const csrf = data.data.csrf;
        var loader = document.getElementById("loader");
        loader.classList.add("nodisplay");

        axios
          .post("/admin/validation", { csrf })
          .then((data) => {
            setResa(data.data.data);
            setCSRF(csrf);

            var loader = document.getElementById("loader");
            loader.classList.add("nodisplay");
          })
          .catch((err) => {
            if (err?.request?.status === 429) {
              Swal.fire({
                icon: "error",
                title: "Trop de requêtes, veuillez réessayer plus tard.",
              });
            } else if (err?.response?.data?.msg) {
              Swal.fire({
                icon: "error",
                title: err.response.data.msg,
              });
            } else {
              Swal.fire(
                "Une erreur a été détécté, merci de réesayer plus tard."
              );
            }
          });
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        } else {
          Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.");
        }
      });
  }, []);

  useEffect(() => {
    let btn = document.querySelector("#btn");
    let sidebar = document.querySelector(".sidebar");

    btn.onclick = function () {
      sidebar.classList.toggle("active");
    };
  });

  const HandleDecision = (id, decision) => {
    const item = Resa?.find((element) => element?.id === id);
    const params = {
      id,
      csrf: CSRF,
      decision,
      token: item.token,
    };

    axios
      .post("/admin/validation/decision", params)
      .then(() => {
        for (let i in Resa) {
          if (id === Resa[i].id) {
            delete Resa[i];
            let tbody = document.getElementsByClassName("tableauscap");
            tbody[i].innerHTML = "";
            Swal.fire(
              `Bien joué !`,
              `Cette résérvation a bien été ${
                decision === "true" ? "validée" : "refusée"
              } !`,
              "success"
            );
            break;
          }
        }
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          }).then(() => {
            if (err?.response?.data.data === "refresh") {
              window.location.reload();
            }
          });
        } else {
          Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.");
        }
      });
  };

  const Deconnexion = () => {
    axios.post("/logout").then(() => {
      window.location.href = "/";
    });
  };

  return (
    <>
      <Helmet>
        <title>Admin Side - STUDIO QUATRE</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div id="loader" className="hiuxxw loaderPay">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 style={{ color: "white" }}>Veuillez patienter</h2>
      </div>
      <main id="main">
        <div className="sidebar">
          <div className="logo_content">
            <div className="logo">
              <i className="bx bxl-c-plus-plus" style={{ fontSize: "30px" }} />
              <div className="logoname" style={{ marginLeft: "5px" }}>
                Le Studio Quatre
              </div>
            </div>
            <i
              className="bx bx-menu-alt-right"
              id="btn"
              style={{ fontSize: "25px" }}
            >
              <Icon icon="bx:menu-alt-right" />
            </i>
          </div>
          <ul className="nav_list">
            <li>
              <NavLink to="/wsq700/dashboard">
                <i className="bx bx-user">
                  <Icon icon="material-symbols:add" />
                </i>
                <span className="link_names">Dashboard</span>
              </NavLink>
              <span className="tooltip">Dashboard</span>
            </li>
            <li>
              <NavLink to="/wsq700/stats">
                <i className="bx bx-user">
                  <Icon icon="material-symbols:query-stats" />
                </i>
                <span className="link_names">Statistiques</span>
              </NavLink>
              <span className="tooltip">Statistiques</span>
            </li>
            <li>
              <NavLink to="/wsq700/privatelinks">
                <i className="bx bx-user">
                  <Icon icon="material-symbols:private-connectivity" />
                </i>
                <span className="link_names">Entreprise</span>
              </NavLink>
              <span className="tooltip">Entreprise</span>
            </li>
            <li>
              <NavLink to="/wsq700/chat">
                <i className="bx bx-user">
                  <Icon icon="material-symbols:chat" />
                </i>
                <span className="link_names">Chat</span>
              </NavLink>
              <span className="tooltip">Chat</span>
            </li>
            <li onClick={Deconnexion}>
              <a href="#logout">
                <i className="bx bx-chat">
                  <Icon icon="material-symbols:logout-rounded" />
                </i>
                <span className="link_names">Déconnexion</span>
              </a>
              <span className="tooltip">Déconnexion</span>
            </li>
          </ul>
        </div>
        <div className="home_content">
          <h1 className="validah1">Inscriptions cours à valider</h1>

          {Resa.length !== 0 ? (
            <>
              <article className="effect-slidebox faq-question">
                <div className="divDisplay">
                  <div className="border-arrow">
                    <div id="arrow" className="arrow-clicked arrow right"></div>
                  </div>
                  <h3>Tableau</h3>
                </div>
                <div className="faq-test displayNull">
                  <table className="fl-table">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Jour</th>
                        <th>Programme</th>
                        <th>Accepter</th>
                        <th>Refuser</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Resa.map((Res, index) => (
                        <>
                          <tr className="tableauscap" key={uuidv4()}>
                            <td>{decode(Resa[index].nom)} </td>
                            <td>{Resa[index].jour} </td>
                            <td>{decode(Resa[index].programme)}</td>
                            <td>
                              <button
                                onClick={() =>
                                  HandleDecision(Resa[index].id, "true")
                                }
                              >
                                Accepter
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  HandleDecision(Resa[index].id, "false")
                                }
                              >
                                Refuser
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </article>
            </>
          ) : null}
        </div>
      </main>
    </>
  );
}
