import { useState } from "react";
import { encode } from "html-entities";
import axios from "axios";
import Swal from "sweetalert2";

export default function AjouterEntreprise(args) {
  const [Entreprise, setEntreprise] = useState("");
  const HandleEntreprise = (e) => setEntreprise(e.target.value);
  const [NomEntreprise, setNomEntreprise] = useState("");
  const HandleNomEntreprise = (e) => setNomEntreprise(e.target.value);

  const [CSRF] = useState(args.type);

  const HandleSubmitEntreprise = (e) => {
    e.preventDefault();

    let NomEntrepriseCC = encode(NomEntreprise);

    if (!/^[a-zA-z0-9]{1,10}$/.test(Entreprise)) {
      Swal.fire(
        "Une erreur !",
        "Le nom peut contenir uniquement des lettres, des chiffres",
        "error",
      );
      return;
    }

    const params = {
      nom: NomEntrepriseCC,
      code: Entreprise,
      csrf: CSRF,
    };

    axios
      .post("/admin/ajoutEntreprise", params)
      .then(() => {
        Swal.fire("Votre entreprise a bien été ajouté !");
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        }
      });
  };

  return (
    <form onSubmit={HandleSubmitEntreprise}>
      <div className="form-element unsetWidth">
        <div className="mt80 titlebox9">Ajouter une Entreprise</div>
        <div className="user-box">
          <input
            className="coursFont"
            value={NomEntreprise}
            onChange={HandleNomEntreprise}
            id="nom"
            type="text"
            name="nom"
            required
          />
          <br />
          <label htmlFor="nom">Nom</label>
        </div>
        <div className="user-box">
          <input
            value={Entreprise}
            onChange={HandleEntreprise}
            id="entreprise"
            type="text"
            name="entreprise"
            required
          />
          <br />
          <label htmlFor="entreprise">Code (limite 10)</label>
          <input type="hidden" value={CSRF} />
        </div>
        <button className="gingerlab" type="submit">
          Ajouter
        </button>
        <hr style={{ border: "1px solid #fff", marginTop: "50px" }} />
      </div>
    </form>
  );
}
