import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Menu from "../components/Navbar";

export default function Blog() {
  return (
    <>
      <Helmet>
        <title>Le Blog Sportif - STUDIO QUATRE</title>
        <meta
          name="description"
          content="Retrouvez ici nos articles sportifs, des articles minceurs aux conseils sportifs."
        />
        <meta property="og:site_name" content="STUDIO QUATRE" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lestudioquatre.com/blog" />
        <meta property="og:title" content="STUDIO QUATRE - Blog" />
        <meta
          property="og:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          property="og:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@studioquatre" />
        <meta name="twitter:title" content="STUDIO QUATRE - Blog" />
        <meta
          name="twitter:description"
          content="STUDIO QUATRE, des coachs sportifs pour votre entreprise"
        />
        <meta
          name="twitter:image"
          content="https://lestudioquatre.com/img/a/lsqensemble.webp"
        />
        <meta name="twitter:url" content="https://lestudioquatre.com/blog" />
        <link rel="canonical" href="https://lestudioquatre.com/blog" />
      </Helmet>
      <Menu />
      <main>
        <h1 className="martop170 h1offer">
          <span>Le Blog</span>
        </h1>
        <div className="cardcontainer">
          <article className="cardcontaining">
            <div>
              <NavLink to="/philosophie-du-studio">
                <img
                  src="img/squared/lsqelast.webp"
                  alt="lsqelast"
                  className="x400"
                />
              </NavLink>
              <div className="textoncard">
                <h2>La philosophie du Studio Quatre</h2>
                <p>Publié le 28 décembre 2021</p>
              </div>
            </div>
          </article>
        </div>
      </main>
      <Footer />
    </>
  );
}
