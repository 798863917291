import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

export default function AjouterNewsletter(args) {
  const [titre, setTitre] = useState("");
  const [texte, setTexte] = useState("");
  const [aim, setAim] = useState("");
  const [limit, setLimit] = useState("");
  const [CSRF] = useState(args.type);

  const HandleSubmitCours = (e) => {
    e.preventDefault();

    if (!titre || !texte || !aim || !limit) {
      Swal.fire("Une erreur !", "Veuillez remplir tous les champs", "error");
      return;
    }

    if (titre.length > 50) {
      Swal.fire(
        "Une erreur !",
        "Le titre est trop long (" +
          titre.length +
          " caractères au lieu de 50 max)",
        "error",
      );
      return;
    }

    if (texte.length > 400) {
      Swal.fire(
        "Une erreur !",
        "Le texte est trop long (" +
          texte.length +
          " caractères au lieu de 400 max)",
        "error",
      );
      return;
    }

    const params = {
      csrf: CSRF,
      titre,
      texte,
      aim: aim.toUpperCase(),
      limit,
    };

    axios
      .post("/admin/newsletter", params)
      .then(() => {
        Swal.fire("Cette newsletter a bien été ajouté.");
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          Swal.fire({
            icon: "error",
            title: "Trop de requêtes, veuillez réessayer plus tard.",
          });
        } else if (err?.response?.data?.msg) {
          Swal.fire({
            icon: "error",
            title: err.response.data.msg,
          });
        }
      });
  };

  return (
    <form onSubmit={HandleSubmitCours}>
      <div className="form-element unsetWidth">
        <div className="mt80 titlebox9">Ajouter une newsletter</div>
        <div className="user-box">
          <input
            className="coursFont"
            value={titre}
            onChange={(e) => setTitre(e.target.value)}
            id="nom"
            type="text"
            name="nom"
            required
          />
          <br />
          <label className="coursFont" htmlFor="nom">
            Titre de la newsletter
          </label>
        </div>
        <p
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          className="coursFont"
        >
          Si vous voulez inclure toutes les entreprise dans cette newsletter
          mettez une étoile '*'
        </p>
        <div className="user-box">
          <input
            className="coursFont"
            value={aim}
            onChange={(e) => setAim(e.target.value)}
            id="entreprise"
            type="text"
            name="entreprise"
            required
          />
          <br />
          <label className="coursFont" htmlFor="entreprise">
            Code Entreprise
          </label>
          <input type="hidden" value={CSRF} />

          <div className="user-box">
            <label className="coursFont" htmlFor="limit">
              Date limite
            </label>
            <input
              style={{ marginTop: "25px" }}
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              type="date"
              id="limit"
              name="limit"
              required
            ></input>
            <br />
          </div>
          <div className="user-box">
            <textarea
              style={{ width: "100%", fontFamily: "sans-serif" }}
              className="coursFont"
              rows="15"
              value={texte}
              onChange={(e) => setTexte(e.target.value)}
              name="programme"
            ></textarea>
          </div>

          <button className="gingerlab" type="submit">
            Ajouter
          </button>
        </div>
      </div>
    </form>
  );
}
