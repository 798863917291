import { Icon } from "@iconify/react"
import axios from "axios"
import { decode } from "html-entities"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { NavLink } from "react-router-dom"
import Swal from "sweetalert2"

export default function AdminChat() {

    const [data, setData] = useState([])

    useEffect(() => {
        axios.post("/admin/getcsrf")
            .then((data) => {
                if (!data.data.login) {
                    window.location.href = "/wsq700/dashboard"
                }

                var loader = document.getElementById("loader");
                loader.classList.add('nodisplay')

                axios.post('/admin/chat/init', { csrf: data.data.csrf })
                    .then((response) => {
                        setData(response.data.sort((a, b) => b.chatAwait - a.chatAwait))
                    })
                    .catch((err) => {
                        if (err?.request?.status === 429) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Trop de requêtes, veuillez réessayer plus tard.'
                            })
                        } else if (err?.response?.data?.msg) {
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.msg
                            })
                        } else {
                            Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                        }
                    })
            })
            .catch((err) => {
                if (err?.request?.status === 429) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Trop de requêtes, veuillez réessayer plus tard.'
                    })
                } else if (err?.response?.data?.msg) {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.msg
                    })
                } else {
                    Swal.fire("Une erreur a été détécté, merci de réesayer plus tard.")
                }
            })
    }, [])

    const Deconnexion = () => {
        axios.post("/logout")
            .then(() => {
                window.location.href = "/"
            })
    }


    return (
        <>
            <Helmet>
                <title>Admin Side - STUDIO QUATRE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div id="loader" className="hiuxxw loaderPay">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                <h2 style={{ color: "white" }}>Veuillez patienter</h2>
            </div>
            <div className="sidebar">
                <div className="logo_content">
                    <div className="logo">
                        <i className="bx bxl-c-plus-plus" style={{ fontSize: '30px' }} />
                        <div className="logoname" style={{ marginLeft: '5px' }}>Le Studio Quatre</div>
                    </div>
                    <i className="bx bx-menu-alt-right" id="btn" style={{ fontSize: '25px' }}>
                        <Icon icon="bx:menu-alt-right" />
                    </i>
                </div>
                <ul className="nav_list">
                    <li>
                        <NavLink to="/wsq700/dashboard">
                            <i className="bx bx-user">
                                <Icon icon="material-symbols:add" />
                            </i>
                            <span className="link_names">Dashboard</span>
                        </NavLink>
                        <span className="tooltip">Dashboard</span>
                    </li>
                    <li>
                        <NavLink to="/wsq700/stats">
                            <i className="bx bx-user">
                                <Icon icon="material-symbols:query-stats" />
                            </i>
                            <span className="link_names">Statistiques</span>
                        </NavLink>
                        <span className="tooltip">Statistiques</span>
                    </li>
                    <li>
                        <NavLink to="/wsq700/validation">
                            <i className="bx bx-user">
                                <Icon icon="grommet-icons:validate" />
                            </i>
                            <span className="link_names">Validation</span>
                        </NavLink>
                        <span className="tooltip">Validation</span>
                    </li>
                    <li>
                        <NavLink to="/wsq700/privatelinks">
                            <i className="bx bx-user">
                                <Icon icon="material-symbols:private-connectivity" />
                            </i>
                            <span className="link_names">Entreprise</span>
                        </NavLink>
                        <span className="tooltip">Entreprise</span>
                    </li>
                    <li onClick={Deconnexion}>
                        <a href="#logout">
                            <i className="bx bx-chat">
                                <Icon icon="material-symbols:logout-rounded" />
                            </i>
                            <span className="link_names">Déconnexion</span>
                        </a>
                        <span className="tooltip">Déconnexion</span>
                    </li>
                </ul>
            </div>
            <div className="home_content cc-container">
                {
                    data.length > 0
                        ?
                        data.map((item, index) => (
                            <a key={index} className="card-chat" href={`/wsq700/chat/frame/${item.code}`}>
                                <h2>{decode(item.nom)}</h2>
                                <p>{
                                    item.chatAwait ? item.chatAwait + " messages non-lus" : ""
                                }</p>
                            </a>
                        ))
                        : <article className="card-chat">
                            <h2>Rien à voir pour le moment</h2>
                        </article>
                }


            </div>
        </>
    )
}