import { useEffect } from "react";

export default function DownloadApp() {
  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  const downloadApp = () => {
    const mobile = getMobileOS();

    switch (mobile) {
      default:
        break;
      case "Android":
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.st4";
        break;
      case "iOS":
        window.location.href =
          "https://apps.apple.com/us/app/studio-quatre/id6466744865";
        break;
      case "Other":
        window.location.href = "/";
        break;
    }
  };

  useEffect(() => {
    downloadApp();
    window.location.href = "/";
  });
}
