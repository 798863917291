import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <section>
          <h3>STUDIO QUATRE</h3>
          <p>
            Une salle de sport personnalisée où le bien être est la priorité
          </p>
        </section>
        <section>
          <h3>Liens</h3>
          <NavLink to="/">Accueil</NavLink>
          <NavLink to="/meditrain">Meditrain</NavLink>
          <NavLink to="/blog">Blog</NavLink>
        </section>
        <section>
          <h3>Légale</h3>
          <NavLink to="/mentions">Mentions légales</NavLink>
          <NavLink to="/confidentialite">Politique de confidentialité</NavLink>
        </section>
      </footer>
      <div className="sub-footer">
        <p id="year">Studio Quatre - {new Date().getFullYear()}</p>
      </div>
    </>
  );
}
